import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ListCustomer() {
    const [customerList, setCustomerList] = useState([]);

    useEffect(() => {
        fetchCustomerList();
    }, []);

    const fetchCustomerList = async () => {
        try {
            const response = await axios.get('http://localhost:8082/customers/all');
            setCustomerList(response.data);
        } catch (error) {
            console.error("An error occurred while fetching the customer list:", error.message);
            // Optionally, display an error message to the user
        }
    };

    return (
        <div className="text-gray-900">
            <div className="p-4 flex">
                <h1 className="text-3xl">Customer List</h1>
            </div>
            <div className="px-3 py-4 flex justify-center">
                <table className="w-full text-md bg-white shadow-md rounded mb-4">
                    <thead>
                        <tr>
                            <th className="text-left p-3 px-5">ID</th>
                            <th className="text-left p-3 px-5">Name</th>
                            <th className="text-left p-3 px-5">Email</th>
                            <th className="text-left p-3 px-5">Mobile</th>
                            <th className="text-left p-3 px-5">Address</th>
                            <th className="text-left p-3 px-5">City</th>
                            <th className="text-left p-3 px-5">State</th>
                        </tr>
                    </thead>
                    <tbody>
                        {customerList.map((customer) => (
                            <tr key={customer.id} className="border-b hover:bg-orange-100 bg-gray-100">
                                <td className="p-3 px-5">{customer.id}</td>
                                <td className="p-3 px-5">{customer.name}</td>
                                <td className="p-3 px-5">{customer.email}</td>
                                <td className="p-3 px-5">{customer.mobile}</td>
                                <td className="p-3 px-5">{customer.address}</td>
                                <td className="p-3 px-5">{customer.city}</td>
                                <td className="p-3 px-5">{customer.state}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ListCustomer;
