import React, { useState, useEffect } from 'react';

function Socialletest() {
  const [activity, setActivity] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchActivity();
  }, []);

  const fetchActivity = async () => {
    try {
      const response = await fetch('http://localhost:8080/social/maxId');
      if (!response.ok) {
        throw new Error('Failed to fetch activity');
      }
      const data = await response.json();
      setActivity(data);
    } catch (error) {
      console.error(error);
      setError('Failed to fetch activity');
    }
  };

  return (
    <div className="container mx-auto py-8">
      {error && <div className="text-red-500">{error}</div>}
      {activity && (
        <div className="max-w-8xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="px-6 py-4">
            <h1 className="text-3xl font-bold text-gray-800 text-center">{activity.head}</h1>
            <div className="flex items-center justify-between mt-4">
              <p className="text-sm font-bold text-gray-600">{activity.newspaper}</p>
              <span className="text-sm font-bold text-gray-600">{activity.date}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{activity.place}</span>
            </div>
            <img src={`data:image/png;base64,${activity.imageData}`} alt="Activity Image" className="mt-4 w-full h-auto" />
            <div className="mt-4">
              <h4 className="text-xl font-semibold text-gray-700">{activity.extra}</h4>
              <p className="mt-2 text-gray-600">{activity.description}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Socialletest;
