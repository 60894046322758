import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import Shippinginfo from '../ProductNavbar/Undercart/Shippinginfo';
import { Link } from 'react-router-dom';

// Import images
import img1 from '../../../img/4.jpg';
import img2 from '../../../img/7.jpg';
import img3 from '../../../img/9.jpg';

const slides = [
  { img: img1 },
  { img: img2 },
  { img: img3 }
];

function PanchgavyCard() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [weight] = useState('12 CUPS');  // Static weight
  const [price] = useState(110.00);  // Static price

  const sliderRef = useRef(null);

  useEffect(() => {
    const slideInterval = 3000; 

    const intervalId = setInterval(() => {
      if (sliderRef.current) {
        const nextIndex = (currentIndex + 1) % slides.length;
        setCurrentIndex(nextIndex);
        sliderRef.current.slickGoTo(nextIndex);
      }
    }, slideInterval);

    return () => clearInterval(intervalId);
  }, [currentIndex]);

    return (
      <div className='bg-gray-100 shadow-lg p-6 md:p-10 lg:p-16 max-w-full mx-auto'>
      <h1 className='text-3xl text-center font-bold text-black mb-6'>Add To Cart</h1>
      <div className='flex flex-col md:flex-row justify-around items-center md:items-start'>
        <div className='bg-white shadow-lg rounded-lg overflow-hidden mb-6 md:mb-0 md:mr-6 w-full md:w-2/6	'>
          <Slider ref={sliderRef}>
            {slides.map((slide, index) => (
              <img key={index} src={slide.img} alt={`Slide ${index}`} className='w-full' />
            ))}
          </Slider>
        </div>
          <div className="p-4 flex flex-col items-start flex-grow">
            <h1 className="text-3xl font-bold text-green-900">PANCHGAVY SUMBRANI</h1>
            <p className='text-gray-600 mb-4'>Dhoop sticks made out of cow dung which is used for Pooja, contributing to bliss and peace of the surroundings as well as acting as a natural deterrent to mosquitoes.</p>
            <p className="mt-2 text-xl text-gray-600 font-medium">Pieces: <span className="font-semibold text-black">12 CUP</span></p>
            <p className="mt-2 text-xl text-gray-600 font-medium">Price: Rs. <span className="font-semibold text-black">110.00</span></p>
            <Link 
            to="/productform" 
            state={{ 
              productName: 'PANCHGAVY SUMBRANI', 
              weight, 
              price 
            }}
          >
            <button className="text-white w-48 mt-8 bg-green-500 hover:bg-green-700 transition-transform hover:scale-105 px-4 py-2 rounded">
              Buy Now
            </button>
          </Link>
  
          </div>
          
        </div>
        <div className='mt-6 p-4'>
          <h1 className='text-xl text-left font-bold text-green-800 mb-4'>Description</h1>
          <ul className='space-y-2 text-gray-700'>
            <li className='text-sm md:text-base'>
              <span className='font-semibold'>✔ ANTIMICROBIAL PROPERTIES :</span> Panchgavya components such as cow urine and dung are believed to possess antimicrobial properties. Sumbrani, derived from these components, may help in disinfecting surfaces, reducing microbial growth, and purifying the environment.
            </li>
            <li className='text-sm md:text-base'>
              <span className='font-semibold'>✔ NATURAL FERTILIZER :</span> Panchgavya Sumbrani can be used as a natural fertilizer in agriculture. It contains nutrients that enrich the soil and promote plant growth. 
            </li>
            <li className='text-sm md:text-base'>
              <span className='font-semibold'>✔ CLEANSING AND PURIFICATION :</span>   In some traditional practices, Panchgavya Sumbrani is used for cleansing and purification rituals. It is believed to purify the surroundings, ward off negative energies, and create a harmonious environment conducive to spiritual practices and well-being.
            </li>
            <li className='text-sm md:text-base'>
              <span className='font-semibold'>✔ SKIN AND HAIR CARE :</span> Sumbrani may be used in skincare routines as a natural cleanser or moisturizer, promoting skin health and radiance. Additionally, it could be incorporated into hair care regimens for nourishing and strengthening hair strands.
            </li>
          </ul>
        </div>
        <Shippinginfo />
      </div>
    );
  }

export default PanchgavyCard;