import React from 'react';
import './Contactcomponent.css';
import { BsBank } from "react-icons/bs";
import { FaQrcode } from "react-icons/fa";
import upiImage from "../../img/SamvedhanaQRFotter.jpeg";
import Footer from './Footer';

function Contactcomponent() {
    return (
        <div className='bg-green-50'>
            <div className="flex flex-wrap justify-center gap-8 px-4 py-8 max-w-full mx-auto ">
                <div className="flex flex-col max-w-3xl">
                    {/* Contact Information */}
                    <div className="mb-8 md:flex md:grid md:grid-cols-2 md:gap-x-8">
                        <div>
                            <h1 className="text-2xl font-bold mb-4 text-green-900">Samvedana Foundation, Sangli</h1>
                            <h4 className="text-lg font-semibold mb-2">Address</h4>
                            <p className="text-sm mb-4">301, Saphair Apartment, 3rd Floor, Mahadevbag, Near Patidar Bhavan, Sangli-416416, Maharashtra, India</p>
                            <h4 className="text-lg font-semibold mb-2">Call</h4>
                            <p className="text-sm mb-4">+91 9423001415</p>
                            <div className="flex space-x-4 mb-4">
                                <a href="tel:+919423001415" className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded">Call</a>
                                <a href="https://maps.app.goo.gl/j5jV8HFbGYHDK94J9" target="_blank" rel="noopener noreferrer" className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded">Get Direction</a>
                            </div>
                        </div>

                        {/* Social Works */}
                        <div className="mb-8">
                            <h1 className="text-2xl font-bold mb-4 text-green-900">Social Works</h1>
                            <div className="mb-4">
                                <h2 className="text-lg font-semibold mb-2">Samvedana Gaushala Sangli</h2>
                                <h4 className="text-md font-semibold">Address</h4>
                                <p className="text-sm mb-4">Budhgaon - Kupwad Rd, Bisur, Maharashtra 416304</p>
                            </div>
                            <div>
                                <h2 className="text-lg font-semibold mb-2">Manasvardhan Punarvasan Kendra</h2>
                                <h4 className="text-md font-semibold">Address</h4>
                                <p className="text-sm mb-4">Gandhi Chowk Raj hotel Bulding, 458/1 B, Mangalwar Peth, Miraj, Maharashtra 416410</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Donation Information */}
                <div className="flex flex-col items-center max-w-3xl   ">
                    <div className="text-center mb-8">
                        <h1 className="text-2xl font-bold text-green-900">For Donation</h1>
                    </div>
                    <div className="flex justify-center gap-8">
                        <div className="max-w-md text-center mb-8">
                            <div className="flex items-center justify-center mb-4">
                                <BsBank className="text-3xl text-blue-500" />
                            </div>
                            <div>
                                <h2 className="text-lg font-semibold mb-2">Bank Details</h2>
                                <h4 className="text-md mb-1">Bank:- <span className='font-bold'>State Bank Of India</span></h4>
                                <h4 className="text-md mb-1">Name :- <span className='font-bold'>SAMVEDANA FOUNDATION</span></h4>

                                <h4 className="text-md mb-1">A/C No.:- <span className='font-bold'>50200033213231</span></h4>
                                <h4 className="text-md">IFSC Code: <span className='font-bold'>HDFC0000222</span></h4>
                            </div>
                        </div>

                        <div className="max-w-md text-center mb-8">
                            <div className="flex items-center justify-center mb-4">
                                <FaQrcode className="text-3xl text-blue-500" />
                            </div>
                            <div>
                                <h2 className="text-lg font-semibold mb-2">UPI</h2>
                                <h4 className="text-md mb-1">UPI98744561230</h4>
                                <img src={upiImage} alt="UPI QR Code" className="w-32 h-auto" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div><Footer /></div>
        </div>
    );
}

export default Contactcomponent;
