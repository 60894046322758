import React, { useEffect, useState } from 'react';
import CarasoualSamvedhan from './CarasoualSamvedhan';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CarasoualData = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch('https://samvedanafoundation.com/exe_files/list_samvedana.php')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        return response.json();
      })
      .then(data => setData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, 
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="container mx-auto py-8">
      {data.length === 0 ? (
        <p>No data available</p>
      ) : (
        <Slider {...settings}>
          {data.map(item => (
            <div key={item.id}>
              <CarasoualSamvedhan
                image={`https://samvedanafoundation.com/exe_files/images/activity_images/${item.PhotoPath}`} // Assuming the image data is in base64 format
                head={item.Head}
                extra={item.ShortDescription}
                description={item.Description}
              />
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default CarasoualData;
