import React from 'react'
import DonationHead from './DonationHead'
import DonationCard from './DonationCard'
import Navbar from '../Navbar/Navbar'
import DonationPayment from './DonationPayment'
import Contactcomponent from '../Contact/Contactcomponent'
import Contactform from '../Aminpannel/Samvedana/Contactform'

function HomeDonation() {
    return (
        <div>
            <div><Navbar /></div>
            <div className='mt-20 sm:pt-0'><DonationHead /></div>

            <div><DonationCard /></div>
            <div><DonationPayment /></div>
            <div><Contactform/></div>
            <div><Contactcomponent /></div>

        </div>
    )
}

export default HomeDonation
