import React, { useState } from 'react';
import axios from 'axios';

function AddSocialAwards() {
    const [formData, setFormData] = useState({
        head: "",
        description: "",
        date: "",
        place: "",
        image: null
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            setFormData({ ...formData, image: file });
        } else {
            alert("Please upload a valid image file.");
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.head || !formData.date || !formData.place) {
            alert("Please fill in all required fields");
            return;
        }

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('head', formData.head);
            formDataToSend.append('description', formData.description);
            formDataToSend.append('date', formData.date);
            formDataToSend.append('place', formData.place);
            if (formData.image) {
                formDataToSend.append('image', formData.image);
            }

            const response = await axios.post('https://samvedanafoundation.com/exe_files/add_social_award.php', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log(response.data);
            alert("Form submitted successfully");
            setFormData({
                head: "",
                description: "",
                date: "",
                place: "",
                image: null
            });
        } catch (error) {
            console.error("An error occurred while submitting the form:", error.message);
            alert("Failed to submit the form");
        }
    }

    return (
        <div>
            <h1 className='text-center text-3xl font-bold'>Social Awards</h1>

            <div className="heading text-center font-bold text-2xl m-5 text-gray-800">New Award Post</div>
            <form className="editor mx-auto w-10/12 flex flex-col text-gray-800 border border-gray-300 p-4 shadow-lg max-w-2xl" onSubmit={handleSubmit}>
                <input 
                    className="title bg-gray-100 border border-gray-300 p-2 mb-4 outline-none" 
                    placeholder="Activity Head" 
                    type="text" 
                    name="head" 
                    value={formData.head} 
                    onChange={handleChange} 
                />
                <textarea 
                    className="description bg-gray-100 sec p-3 mb-4 h-60 border border-gray-300 outline-none" 
                    placeholder="Describe everything about this post here" 
                    name="description" 
                    value={formData.description} 
                    onChange={handleChange}
                ></textarea>
                <input 
                    type="file" 
                    className="title bg-gray-100 border border-gray-300 p-2 mb-4 outline-none" 
                    placeholder="Upload Image" 
                    accept="image/*" 
                    onChange={handleImageChange} 
                />
                <input 
                    className="title bg-gray-100 border border-gray-300 p-2 mb-4 outline-none" 
                    placeholder="Select Date" 
                    type="date" 
                    name="date" 
                    value={formData.date} 
                    onChange={handleChange} 
                />
                <input 
                    className="title bg-gray-100 border border-gray-300 p-2 mb-4 outline-none" 
                    placeholder="Activity Place" 
                    type="text" 
                    name="place" 
                    value={formData.place} 
                    onChange={handleChange} 
                />
                <div className="buttons flex">
                    <button 
                        type="submit" 
                        className="btn border border-indigo-500 p-1 px-4 font-semibold cursor-pointer text-gray-200 ml-2 bg-indigo-500"
                    >
                        Post
                    </button>
                </div>
            </form>
        </div>
    );
}

export default AddSocialAwards;
