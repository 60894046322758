import React from 'react';
import { MdLocalPhone, MdOutlineMail } from 'react-icons/md';
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";


function Contactform() {
    return (
        <div className=' p-8 '>
           
            <div className="grid sm:grid-cols-2 items-center gap-20 my-6 mx-auto max-w-4xl bg-white text-[#333] font-[sans-serif] ">
                <div>
                    <div className="mt-12">
                        <h2 className="text-lg text-purple-800 font-extrabold">Call</h2>
                        <ul className="mt-3">
                            <li className="flex items-center">
                                <div className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                                    <p className="text-purple-800 font-bold" aria-label="Phone Icon"><MdLocalPhone /></p>
                                </div>
                                <a href="tel:+919423001415" className="text-gray-900 text-sm ml-3">
                                    <small className="block font-bold">Call</small>
                                    <strong className="text-lg font-bold">+91 9423001415</strong>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="mt-12">
                        <h2 className="text-lg text-purple-800 font-extrabold">Email</h2>
                        <ul className="mt-3">
                            <li className="flex items-center">
                                <div className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                                    <p className="text-purple-800 font-bold" aria-label="Mail Icon"><IoMdMail /></p>
                                </div>
                                <a href="mailto:victorent197@gmail.com" className="text-text-gray-900 text-sm ml-3">
                                    <small className="block font-bold">Mail</small>
                                    <strong className="text-lg font-bold">samvedanafoundation.sangli@gmail.com</strong>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="mt-12">
                        <h2 className="text-lg text-purple-800 font-extrabold">Address</h2>
                        <ul className="mt-3">
                            <li className="flex items-center">
                                <div className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                                    <span className="text-purple-800 font-bold" aria-label="Mail Icon"><FaLocationDot /></span>
                                </div>
                                <a className="text-text-gray-900 text-sm ml-3">
                                    <small className="block font-bold">Address</small>
                                    <strong className="text-lg font-bold">301, Saphair Apartment, 3rd Floor, Mahadevbag, Near Patidar Bhavan, Sangli-416416, Maharashtra, India</strong>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="flex space-x-4 mb-4 mt-4">
                        <a href="tel:+919423001415" className="bg-purple-600 hover:bg-purple-800 text-white py-2 px-4 rounded">Call</a>
                        <a href="https://maps.app.goo.gl/YXH4JGETPqGcRBMB9" target="_blank" rel="noopener noreferrer" className="bg-purple-600 hover:bg-purple-800 text-white py-2 px-4 rounded">Get Direction</a>
                    </div>

                </div>
                <form className="ml-auto space-y-4">
                    <h1 className="text-purple-800 font-bold text-xl">Enquire now</h1>
                    <input type="text" placeholder="Name" className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm outline-purple-800" />
                    <input type="email" placeholder="Email" className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm outline-purple-800" />
                    <input type="text" placeholder="Subject" className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm outline-purple-800" />
                    <textarea placeholder="Message" rows="6" className="w-full rounded-md px-4 bg-gray-100 text-sm pt-3 outline-purple-800"></textarea>
                    <button type="submit" className="text-white bg-purple-600 hover:bg-purple-800 font-semibold rounded-md text-sm px-4 py-3 w-full">Send</button>
                </form>
            </div>
            <div className="relative w-full h-96">
                <iframe
                    className="absolute top-0 left-0 w-full h-full"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9902.958826814982!2d74.53683201328006!3d16.870324735668266!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc119d000000001%3A0xf3680fdc6798e3f1!2z4KS44KSC4KS14KWH4KSm4KSo4KS-IOCkq-CljOCkguCkoeClh-CktuCkqA!5e0!3m2!1smr!2sin!4v1716448561591!5m2!1smr!2sin"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen
                    aria-hidden="false"
                    tabIndex="0"
                ></iframe>
            </div>
        </div>
    );


}

export default Contactform;
