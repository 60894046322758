import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ListSocial() {
    const [gaushalaList, setGaushalaList] = useState([]);

    useEffect(() => {
        fetchGaushalaList();
    }, []);

    const fetchGaushalaList = async () => {
        try {
            const response = await axios.get('https://samvedanafoundation.com/exe_files/list_social_news.php');
            const newsData = response.data;
            
            // Check if the response data is empty
            if (Array.isArray(newsData) && newsData.length === 0) {
                setGaushalaList([]); // Set empty array if no data is found
            } else {
                setGaushalaList(newsData); // Set the actual news data
            }
        } catch (error) {
            console.error("An error occurred while fetching the News list:", error.message);
            // Handle error case, possibly set an empty state or show an error message
            setGaushalaList([]); // Optionally set empty array on error
        }
    };

    const deleteGaushala = async (index) => {
        try {
            const samvedanaItem = gaushalaList[index];
            if (!samvedanaItem || !samvedanaItem.NewsId) {
                throw new Error("Item or ActivityId not found");
            }

            const response = await axios.post('https://samvedanafoundation.com/exe_files/delete_social_news.php', 
                JSON.stringify({ Id: samvedanaItem.NewsId }), 
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data.response === "D") {
                setGaushalaList(gaushalaList.filter((_, i) => i !== index));
                alert("Deleted successfully");
            } else {
                alert("Error: " + (response.data.msg || "Unknown error"));
            }
        } catch (error) {
            console.error("Error deleting:", error.message);
            alert("Error deleting. Please try again.");
        }
    };

    return (
        <div>
            <div className="text-gray-900 ">
                <div className="p-4 flex">
                    <h1 className="text-3xl">List Gaushala</h1>
                </div>
                <div className="px-3 py-4 flex justify-center">
                    <table className="w-full text-md bg-white shadow-md rounded mb-4">
                        <thead>
                            <tr>
                                <th className="text-left p-3 px-5">Head</th>
                                <th className="text-left p-3 px-5">Extra</th>
                                <th className="text-left p-3 px-5">Description</th>
                                <th className="text-left p-3 px-5">Date</th>
                                <th className="text-left p-3 px-5">Place</th>
                                <th className="text-left p-3 px-5">Newspaper</th>
                                <th className="text-left p-3 px-5">Image</th>
                                <th className="text-left p-3 px-5">Edit/Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {gaushalaList.map((gaushalaItem, index) => (
                                <tr key={index} className={index % 2 === 0 ? 'border-b hover:bg-orange-100 bg-gray-100' : 'border-b hover:bg-orange-100'}>
                                    <td className="p-3 px-5">{gaushalaItem.Head}</td>
                                    <td className="p-3 px-5">{gaushalaItem.ShortDescription}</td>
                                    <td className="p-3 px-5">{gaushalaItem.Description}</td>
                                    <td className="p-3 px-5">{gaushalaItem.Date}</td>
                                    <td className="p-3 px-5">{gaushalaItem.Place}</td>
                                    <td className="p-3 px-5">{gaushalaItem.NewsPaper}</td>
                                    <td className="p-3 px-5">
                                        <img src={`https://samvedanafoundation.com/exe_files/images/activity_images/${gaushalaItem.PhotoPath}`} alt="Gaushala" style={{ maxWidth: '100px' }} />
                                    </td>
                                    <td className="p-3 px-5 flex justify-end">
                                        <button type="button" className="mr-3 text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline">Save</button>
                                        <button
                                            type="button"
                                            onClick={() => deleteGaushala(index)}
                                            className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default ListSocial;
