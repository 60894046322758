import React, { useState } from 'react';
import axios from 'axios';

function AddVideoSam() {
    const [formData, setFormData] = useState({
        title: "",
        youtubeUrl: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.title || !formData.youtubeUrl) {
            alert("Please fill in all required fields");
            return;
        }

        try {
            const formDataToSend = new FormData();
            for (let key in formData) {
                formDataToSend.append(key, formData[key]);
            }

            // Send POST request to the backend
            const response = await axios.post('https://samvedanafoundation.com/exe_files/add_samvedana_video.php', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log(response.data);
            alert("Form submitted successfully");
        } catch (error) {
            console.error("An error occurred while submitting the form:", error.message);
            alert("Failed to submit the form");
        }
    }

    return (
        <div>
            <h1 className='text-center text-3xl font-bold'>Samvedana Video Add</h1>

            <div className="heading text-center font-bold text-2xl m-5 text-gray-800">New Video Post</div>
            <form className="editor mx-auto w-10/12 flex flex-col text-gray-800 border border-gray-300 p-4 shadow-lg max-w-2xl" onSubmit={handleSubmit}>
                <input 
                    className="title bg-gray-100 border border-gray-300 p-2 mb-4 outline-none" 
                    placeholder="Title" 
                    type="text" 
                    name="title" 
                    value={formData.title} 
                    onChange={handleChange} 
                />
                <input 
                    className="title bg-gray-100 border border-gray-300 p-2 mb-4 outline-none" 
                    placeholder="YouTube URL" 
                    type="text" 
                    name="youtubeUrl" 
                    value={formData.youtubeUrl} 
                    onChange={handleChange} 
                />
                <div className="buttons flex">
                    <button 
                        type="submit" 
                        className="btn border border-indigo-500 p-1 px-4 font-semibold cursor-pointer text-gray-200 ml-2 bg-indigo-500"
                    >
                        Post
                    </button>
                </div>
            </form>
        </div>
    );
}

export default AddVideoSam;
