import React, { useState, useEffect, useRef } from 'react';
import Shippinginfo from '../ProductNavbar/Undercart/Shippinginfo';
import Slider from 'react-slick';

import { Link } from 'react-router-dom';
import img1 from '../../../img/10.jpg';
import img2 from '../../../img/12.jpg';
import img3 from '../../../img/11.jpg';

const slides = [
  { img: img1 },
  { img: img2 },
  { img: img3 }
];

function GheeHomeCard() {
  const [weight, setWeight] = useState('0.5kg');
  const [price, setPrice] = useState(950);

  const handleWeightChange = (selectedWeight) => {
    if (selectedWeight === '1kg') {
      setWeight('1kg');
      setPrice(1800);
    } else {
      setWeight('0.5kg');
      setPrice(950);
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);

  useEffect(() => {
    const slideInterval = 3000; 

    const intervalId = setInterval(() => {
      if (sliderRef.current) {
        const nextIndex = (currentIndex + 1) % slides.length;
        setCurrentIndex(nextIndex);
        sliderRef.current.slickGoTo(nextIndex);
      }
    }, slideInterval);

    return () => clearInterval(intervalId);
  }, [currentIndex]);


  return (
    <div className='bg-gray-100 shadow-lg p-6 md:p-10 lg:p-16 max-w-full mx-auto'>
      <h1 className='text-3xl text-center font-bold text-black mb-6'>Add To Cart</h1>
      <div className='flex flex-col md:flex-row justify-around items-center md:items-start'>
        <div className='bg-white shadow-lg rounded-lg overflow-hidden mb-6 md:mb-0 md:mr-6 w-full md:w-2/6	'>
          <Slider ref={sliderRef}>
            {slides.map((slide, index) => (
              <img key={index} src={slide.img} alt={`Slide ${index}`} className='w-full' />
            ))}
          </Slider>
        </div>
        <div className="flex flex-col justify-center items-start   h-[50vh] md:w-1/3">
          <h1 className="text-lg md:text-3xl text-green-900 font-bold mb-2">GIR A2 GHEE</h1>
          <p className='text-gray-600 mb-4'>The traditional way of extracting of GHEE from milk of GIR COWS.</p>

          <div className="mt-2 flex space-x-2 mb-4">
            <button
              className={`px-4 py-2 rounded-lg ${weight === '0.5kg' ? 'bg-green-400 text-white' : 'bg-gray-100'}`}
              onClick={() => handleWeightChange('0.5kg')}
            >
              0.5kg
            </button>
            <button
              className={`px-4 py-2 rounded-lg ${weight === '1kg' ? 'bg-green-400 text-white' : 'bg-gray-200'}`}
              onClick={() => handleWeightChange('1kg')}
            >
              1kg
            </button>
          </div>
          <p className="mt-2 text-gray-600 text-xl font-medium">Weight: <span className="font-semibold text-black">{weight}</span></p>
          <p className="mt-2 text-gray-600 text-xl font-medium">Price: Rs.<span className="font-semibold text-black">{price.toFixed(2)}</span></p>
          <Link 
            to="/productform" 
            state={{ 
              productName: 'GIR A2 GHEE', 
              weight, 
              price 
            }}
          >
            <button className="text-white w-48 mt-8 bg-green-500 hover:bg-green-700 transition-transform hover:scale-105 px-4 py-2 rounded">
              Buy Now
            </button>
          </Link>
        </div>
      </div>
      <div className='mt-6 p-4'>
        <h1 className='text-xl text-left font-bold text-green-800 mb-4'>Description</h1>
        <ul className='space-y-2 text-gray-700'>
          <li className='text-sm md:text-base'>
            <span className='font-semibold'>✔ PURE GIR COW GHEE:</span> Samvedana Gaushala gir cow ghee is nutritious, healthy, pure and unadulterated ghee with a2 proteins made from a2 milk of Indian native cows. Unlike other ghee products that claim to be organic and without lactose, our ghee butter is 100% natural and organic, produced by grass-fed cow milk in India.
          </li>
          <li className='text-sm md:text-base'>
            <span className='font-semibold'>✔ PURE GIR GHEE SOURCED FROM GIR COW:</span> Gir Ghee (Pure A2 Gir Cow Ghee) is made from milk collected from grass-fed desi gir cows. Grazing upon medicinal grasses in pastures makes the milk of desi gir cows a nectar. Now this nectar gives us best of A2 ghee. These cows are grass-fed and are not factory-farmed and are free from cross-breeding, which gives our Ghee its special, natural qualities.
          </li>
          <li className='text-sm md:text-base'>
            <span className='font-semibold'>✔ GIR COW BENEFITS:</span> Gir Cow A2 Cultured Ghee is gluten free, easy to digest and safe for most people with lactose or casein sensitivities. Our ghee is derived from the high breed Gir cow, known for producing the highly regarded A2 milk. Unlike regular milk, A2 milk contains only A2 protein which is healthier and safer than A1 protein.
          </li>
          <li className='text-sm md:text-base'>
            <span className='font-semibold'>✔ BILONA TRADITIONAL METHOD:</span> We prepare Desi-Ghee by traditional method. This Vedic method is the best process to prepare Desi-Ghee. In market ghee is produced from milk cream through machines and chemicals. The Ghee prepared with machines and chemicals does not have medicinal properties. At our centre we take care that Ghee is made by Ayurvedic method which is recommended by Indian literature.
          </li>
          <li className='text-sm md:text-base'>
            <span className='font-semibold'>✔ SOCIAL RESPONSIBILITY:</span> We are donating a dedicated share of our profit to the Gaushala's and farmers who are protecting and taking care of old age desi cows, so that old age cows should not go to slaughter houses.
          </li>
          <li className='text-sm md:text-base'>
            <span className='font-semibold'>✔ TRADITIONAL RITUAL:</span> We follow Hindu tradition by not using any cow's milk for 15 days after it gives birth to a calf, making our Ghee appropriate for Holy Rituals. Milk isn’t forced from the cows and is extracted only after the calf has had sufficient milk.
          </li>
        </ul>

      </div>
      <Shippinginfo/>

    </div>
  );
}

export default GheeHomeCard;
