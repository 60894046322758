import React, { useState, useEffect } from 'react';
import axios from 'axios';

function GalleryGaushala() {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://samvedanafoundation.com/exe_files/list_gaushala.php');

      // Check if the response indicates no data found
      if (response.data.response === 'E') {
        setActivities([]); // Set activities to an empty array
      } else if (Array.isArray(response.data)) {
        setActivities(response.data); // Set activities if the response is an array
      } else {
        setActivities([]);
        setError('Unexpected data format.');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const visibleActivities = showAll ? activities : activities.slice(0, 3);

  const handleShowAll = () => {
    setShowAll(true);
  };

  if (loading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  if (error) {
    return <div className="text-center mt-8">Error: {error}</div>;
  }

  // Check if there are activities to display
  const hasActivities = Array.isArray(activities) && activities.length > 0;

  return (
    <div>
      {hasActivities ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-4">
          {visibleActivities.map((activity, index) => (
            <figure key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img
                src={`https://samvedanafoundation.com/exe_files/images/activity_images/${activity.PhotoPath}`}
                alt="Activity"
                className="w-full h-64 object-cover object-center"
              />
              <div className="p-4">
                <h1 className="font-bold text-xl mb-2 text-orange-500">{activity.Head}</h1>
                <h5 className="text-gray-900 font-semibold">{activity.ShortDescription}</h5>
                <p className="text-gray-600">{activity.Description}</p>
                <p className="mt-2">
                  <strong>{activity.Place}</strong>, <span>{activity.Date}</span>
                </p>
              </div>
            </figure>
          ))}
          {!showAll && activities.length > 3 && (
            <div className="text-center mt-4 mb-8">
              <button
                onClick={handleShowAll}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                See More
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="text-center mt-8">No activities found.</div>
      )}
    </div>
  );
}

export default GalleryGaushala;
