import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import Shippinginfo from '../ProductNavbar/Undercart/Shippinginfo';
import { Link } from 'react-router-dom';

import img1 from '../../../img/6.jpg';
import img2 from '../../../img/2.jpg';
import img3 from '../../../img/3.jpg';

const slides = [
  { img: img1 },
  { img: img2 },
  { img: img3 }
];

function DiyaCard() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [weight] = useState('10 PCS');  // Static weight
  const [price] = useState(100.00);  // Static price

  const sliderRef = useRef(null);

  useEffect(() => {
    const slideInterval = 3000;

    const intervalId = setInterval(() => {
      if (sliderRef.current) {
        const nextIndex = (currentIndex + 1) % slides.length;
        setCurrentIndex(nextIndex);
        sliderRef.current.slickGoTo(nextIndex);
      }
    }, slideInterval);

    return () => clearInterval(intervalId);
  }, [currentIndex]);
  
  return (
    <div className='bg-gray-100 shadow-lg p-6 md:p-10 lg:p-16 max-w-full mx-auto'>
      <h1 className='text-3xl text-center font-bold text-black mb-6'>Add To Cart</h1>
      <div className='flex flex-col md:flex-row justify-around items-center md:items-start'>
        <div className='bg-white shadow-lg rounded-lg overflow-hidden mb-6 md:mb-0 md:mr-6 w-full md:w-2/6	'>
          <Slider ref={sliderRef}>
            {slides.map((slide, index) => (
              <img key={index} src={slide.img} alt={`Slide ${index}`} className='w-full' />
            ))}
          </Slider>
        </div>
        <div className="flex flex-col justify-center items-start   h-[50vh] md:w-1/3">
          <h1 className="text-3xl font-bold text-green-900">COW DUNG DIYA</h1>
          <p className='text-gray-600 mb-4'>Made from Panch Gavya involving 5 ingredients derived from milk of Gir cows.</p>
          <p className="mt-2 text-xl text-gray-600 font-medium">Pieces: <span className="font-semibold text-black">10 PCS</span></p>
          <p className="mt-2 text-xl text-gray-600 font-medium">Price: Rs. <span className="font-semibold text-black">100.00</span></p>
          <Link 
            to="/productform" 
            state={{ 
              productName: 'COW DUNG DIYA', 
              weight, 
              price 
            }}
          >
            <button className="text-white w-48 mt-8 bg-green-500 hover:bg-green-700 transition-transform hover:scale-105 px-4 py-2 rounded">
              Buy Now
            </button>
          </Link>
        </div>
      </div>
      <div className='mt-6 p-4'>
        <h1 className='text-xl text-left font-bold text-green-800 mb-4'>Description</h1>
        <ul className='space-y-2 text-gray-700'>
          <li className='text-sm md:text-base'>
            <span className='font-semibold'>✔ ECO-FRIENDLY :</span> Cow dung diyas are considered environmentally friendly because they are made from natural materials that are biodegradable and renewable. Using cow dung for diyas reduces the need for synthetic materials that may harm the environment.
          </li>
          <li className='text-sm md:text-base'>
            <span className='font-semibold'>✔ RENEWABLE RESOURCE :</span> Cow dung is readily available in rural areas where cows are commonly kept for milk and agricultural purposes. Using cow dung for diyas utilizes a resource that would otherwise go to waste.
          </li>
          <li className='text-sm md:text-base'>
            <span className='font-semibold'>✔ TRADITIONAL AND CULTURAL SIGNIFICANCE :</span>  In many cultures, particularly in India, cow dung holds religious and cultural significance. Using cow dung diyas in religious ceremonies and festivals is a way to honor tradition and connect with cultural roots.
          </li>
          <li className='text-sm md:text-base'>
            <span className='font-semibold'>✔ COST-EFFECTIVE :</span> Cow dung diyas are often inexpensive to make, especially in rural areas where cow dung is abundant. This makes them a cost-effective lighting option for households and communities with limited resources.
          </li>
        </ul>
      </div>
      <Shippinginfo />
    </div>
  );
}

export default DiyaCard;
