import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { IoCallSharp } from 'react-icons/io5';

import goushala from '../../../img/ManasLogo.png';
import GoImg from '../../../img/manabg.jpg';
import backgroundImage from '../../../img/bg-horizone.jpg';

import ManasHelth0 from '../../../img/ManasHelth0.png';
import ManasHelth1 from '../../../img/ManasHelth1.png';
import ManasHelth2 from '../../../img/ManasHelth2.png';
import ManasHelth3 from '../../../img/ManasHelth3.jpg';
import ManasHelth4 from '../../../img/ManasHelth4.png';
import ManasHelth5 from '../../../img/ManasHelth5.png';
import ManasHelth6 from '../../../img/ManasHelth6.jpg';
import ManasHelth7 from '../../../img/ManasHelth7.jpg';
import ManasHelth8 from '../../../img/ManasHelth8.png';
import ManasHelth9 from '../../../img/ManasHelth9.png';

function ManaswardhanHome() {
    return (
        <div>
            <div className="container mx-auto px-4 pt-8 sm:pt-16" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 justify-center items-center">
                    <img src={goushala} alt="Gaushala Logo" className="w-full sm:max-w-xs" />
                    <div>
                        <div className="flex items-center mb-4">
                            <FaMapMarkerAlt className="text-teal-700 sm:text-xl" />
                            <p className="ml-2 sm:text-base font-bold text-teal-700">Gandhi Chowk Raj Hotel Building, 458/1 B, Mangalwar Peth, Miraj, Maharashtra 416410</p>
                        </div>
                        <div className="flex items-center">
                            <IoCallSharp className="text-teal-700 sm:text-xl" />
                            <p className="ml-2 sm:text-base font-bold text-teal-700">+91 9423001415</p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row items-center ">
                    <div className="w-full sm:w-1/2 mb-8 sm:mb-0">
                        <img src={GoImg} alt="Gaushala" className="w-full rounded-lg shadow-lg" />
                    </div>
                    <div className="w-full sm:w-1/2 p-8 rounded-lg ">
                        <h1 className="text-3xl font-bold mb-4 text-teal-700">Manasvardhan Punarvasan Kendra, Miraj</h1>
                        <p className="text-gray-900 text-lg font-semibold">
                            Manasvardhan Punarvasan Kendra, Miraj is working to bring the mentally ill and addicted people into the mainstream. Today, more than 50 mentally ill and addicted patients are being treated here. The center has specialties of psychologists, psychiatrists, counselors, specialists, experienced doctors, experienced nurses, daycare center, excellent accommodation and food, exercise, yoga, training, and entertainment.
                        </p>
                        <p className="text-gray-900 text-lg font-semibold">
                            Under the same roof of the Human Development Rehabilitation Center, counseling, group discussions, role play, brain-stimulating games, yoga, meditation, and economic rehabilitation are available at a very reasonable cost. All comforts are available for the treatment of diseases such as schizophrenia, OCD, personality disorders, depression, ADHD, dementia, etc. There is a separate arrangement for children with mental disabilities such as mental retardation, ADHD, autism, learning disorders, Down's syndrome.
                        </p>
                    </div>
                </div>
            </div>
            <div className="container mx-auto px-4 mt-8">
                <h1 className="text-2xl sm:text-3xl font-bold mb-4 text-teal-700 text-center">Manasvardhan Punarvasan Health Services</h1>
                <div className="flex flex-wrap justify-around sm:py-10">
                    <ServiceCard image={ManasHelth1} title="Medical attention" />
                    <ServiceCard image={ManasHelth2} title="Daily visit by a specialised doctor" />
                    <ServiceCard image={ManasHelth3} title="Resident physiatrist" />
                    <ServiceCard image={ManasHelth4} title="All meals" />
                    <ServiceCard image={ManasHelth5} title="Counselling" />
                    <ServiceCard image={ManasHelth6} title="Activity and Gaming  " />
                    <ServiceCard image={ManasHelth7} title="Medicine and first aid " />
                    <ServiceCard image={ManasHelth8} title="Exercise / Yoga " />
                    <ServiceCard image={ManasHelth9} title="Physiotherapy " />
                    <ServiceCard image={ManasHelth0} title="Housing " />


                </div>
            </div>
            <div className="container mx-auto px-4 mt-8">
                <h1 className="text-2xl sm:text-3xl font-bold mb-4 text-teal-700 text-center">New Premises:-</h1>
                <ul className="text-xl font-semibold tracking-wider list-disc">
                    <li className="py-2">The new facility will move to a bigger new build space which is by itself a standalone building. This premises is being taken on long lease.</li>
                    <li className="py-2">The new facility will house about 150 people comprising of people who are mentally challenged for various reasons (not necessarily by birth), addicts, old age people, </li>
                    <li className="py-2">The new premises will additionally house patients who are discharged from hospital but need nursing care.</li>
                    <li className="py-2">The new premises will welcome people who may need nursing / medical care for a short duration.</li>
                    <li className="py-2">Many a times, many families are faced with a dilemma of having to leave behind person(s) needing medical attention while they have to travel. Travelling with such patients due to medical and other reasons, for a short duration, is not possible. The new building will provide secure and nursing attention to all such people.</li>
                </ul>
            </div>
        </div>
    );
}

const ServiceCard = ({ image, title }) => (
    <div className="w-full sm:w-1/3 text-center text-xl font-bold">
        <img className="w-40 h-40 mx-auto" src={image} alt={title} />
        <h1 className="py-2">{title}</h1>
    </div>
);

export default ManaswardhanHome;
