import React from 'react'
import { FaFacebook, FaWhatsapp, FaInstagram, FaTwitter, FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Footer() {
    return (

        <div class="bg-green-50 border-t">
            <div class="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
                <nav class="flex flex-wrap justify-center -mx-5 -my-2">
                    <div class="px-5 py-2">
                       <Link to="/about"> <a href="#" class="text-base leading-6 text-gray-500 hover:text-green-500">
                            About Us
                        </a></Link>
                    </div>
                    <div class="px-5 py-2">
                       <Link to="/activitygaushala"> <a href="#" class="text-base leading-6 text-gray-500 hover:text-green-500">
                       Gaushala
                        </a></Link>
                    </div>
                    <div class="px-5 py-2">
                       <Link to="/activitymanasvardhan"> <a href="#" class="text-base leading-6 text-gray-500 hover:text-green-500">
                       Manasvardhan
                        </a></Link>
                    </div>
                    <div class="px-5 py-2">
                       <Link to="/news"> <a href="#" class="text-base leading-6 text-gray-500 hover:text-green-500">
                       News & Events
                        </a></Link>
                    </div>
                    <div class="px-5 py-2">
                       <Link to="/award"> <a href="#" class="text-base leading-6 text-gray-500 hover:text-green-500">
                            Awards
                        </a></Link>
                    </div>

                </nav>
                <div className='flex justify-center mt-8 space-x-6'>
                    <a href="https://www.facebook.com/samvedanafoundation" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-green-500">
                        <FaFacebook className="w-6 h-6" />
                    </a>
                    <a href="https://wa.me/919423001415" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-green-500">
                        <FaWhatsapp className="w-6 h-6" />
                    </a>
                    <a href="https://www.instagram.com/samvedana_foundation_sangli/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-green-500">
                        <FaInstagram className="w-6 h-6" />
                    </a>
                    <a href="https://twitter.com/Ronaksh46083798" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-green-500">
                        <FaTwitter className="w-6 h-6" />
                    </a>
                    <a href="mailto:sail1415@gmail.com" className="text-gray-400 hover:text-green-500">
                        <FaEnvelope className="w-6 h-6" />
                    </a>
                </div>
                <p className="mt-8 text-base leading-6 text-center text-gray-400">
                    Copyright © 2024 Samvedana Foundation, Sangli. All rights reserved by{' '}
                    <a href="https://www.businessmantra.info/index.php" className="font-bold text-green-500" target="_blank" rel="noopener noreferrer">
                        Business Mantra
                    </a>
                </p>
            </div>
        </div>
    )
}

export default Footer
