import React, { useState } from 'react';
import gpay from '../../img/gpay.png';
import phonepay from '../../img/phone-pay.png';
import paytm from '../../img/paytm.png';
import bhim from '../../img/bhim.png';
import UPI from '../../img/UPI.png';
import qrCodeImage from '../../img/SamvedhanaQR.jpeg';

function UPIPayment() {
  const [showQRCode, setShowQRCode] = useState(false);

  const handleQRCodeToggle = () => {
    setShowQRCode(!showQRCode);
  };

  return (
    <div className='flex flex-col items-center justify-center w-96'>
      {showQRCode && (
        <div className="mt-4 mb-4">
          <img src={qrCodeImage} alt="QR Code" />
        </div>
      )}
      <div className='flex flex-col items-center  bg-blue-200 w-full h-36 '>
        <img src={UPI} className='w-16 h-16 mt-4' alt='UPI Logo' />
        <p className='text-center sm:text-left text-green-900 font-bold text-lg'>Accepted Here</p>
      </div>
      <div className='grid grid-cols-2 gap-1 mt-2'>
        <div className="border border-blue-800 h-36 w-36 flex justify-center items-center">
          <img src={gpay} alt='Google Pay' className='w-24 h-24' />
        </div>
        <div className="border border-blue-800 h-36 w-36 flex justify-center items-center">
          <img src={phonepay} alt='PhonePe' className='w-24 h-24' />
        </div>
        <div className="border border-blue-800 h-36 w-36 flex justify-center items-center">
          <img src={paytm} alt='Paytm' className='w-24 h-24' />
        </div>
        <div className="border border-blue-800 h-36 w-36 flex justify-center items-center">
          <img src={bhim} alt='BHIM' className='w-24 h-24' />
        </div>
      </div>
      <button 
        className='mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4'
        onClick={handleQRCodeToggle}
      >
        Donate via UPI
      </button>
    </div>
  );
}

export default UPIPayment;
