import React from 'react'
import AboutGaushala from './AboutGaushala'
import BodyActivites from './BodyActivites'
import GallaryGaushala from './GallaryGaushala'
import Navbar from '../../Navbar/Navbar'
import GaushalaProduct from './GaushalaProduct'
import GaushalaHome from './GaushalaHome'
import Contactcomponent from '../../Contact/Contactcomponent'
import Contactform from './Contactform'
import ProductHome from '../../Product/ProductNavbar/Addcart/ProductHome'

function HomepageGaushala() {
  return (
    <div>
      <div><Navbar /></div>
      <div className='mt-10 sm:pt-0'><GaushalaHome /></div>

      <div><ProductHome /></div>
      <div className="mt-8 space-x-5 flex justify-center">
        <h1 className="pb-5 text-3xl font-bold  text-yellow-600">Work and Activites for Samvedana Gaushala </h1>
      </div>
      <div><BodyActivites /></div>
      <div className='mt-10 sm:pt-0 mb-10'><GallaryGaushala /></div>
      <div><Contactform/></div>

      <div className='mt-10 sm:pt-0 mb-10'><Contactcomponent /></div>



    </div>
  )
}

export default HomepageGaushala
