import React from 'react'
import Navbar from '../../Navbar/Navbar'
import HomeAwards from './HomeAwards'

function Award() {
  return (
    <div>
    <div><Navbar /></div>
    <div className="mt-8 space-x-5 flex justify-center  sm:pt-0">
      <h1 className="pb-5 text-3xl font-bold  text-green-700 mt-20">Honoring Excellence: A Glimpse into Our Social and Professional Awards</h1>
    </div>
    <div className=''><HomeAwards /></div>

   
   

  </div>
  )
}

export default Award
