import React, { useState } from 'react';
import GallarySocial from './Social/GallarySocial'; 
import GallaryNews from './News/GallaryNews';

function HomeNewsEvent() {
  const [showSocial, setShowSocial] = useState(false);

  const handleShowSocial = () => {
    setShowSocial(true);
  };

  const handleShowProfesional = () => {
    setShowSocial(false);
  };

  return (
    <div className="container mx-auto py-8">
      <div className="flex justify-center space-x-4">
        <button onClick={handleShowSocial} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">News</button>
        <button onClick={handleShowProfesional} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Social Event</button>
      </div>
      <div className="mt-8">
        {showSocial ? <GallaryNews /> : <GallarySocial />}
      </div>
    </div>
  );
}

export default HomeNewsEvent;
