import React, { useState, useEffect } from 'react';
import VideoList from './VideoList';
import LatestVideo from './LatestVideo';

const YouTubePage = () => {
  const [videos, setVideos] = useState([]);
  const [latestVideo, setLatestVideo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [videosResponse, latestVideoResponse] = await Promise.all([
          fetch('https://samvedanafoundation.com/exe_files/list_samvedana_video.php').then(response => response.json()),
          fetch('https://samvedanafoundation.com/exe_files/latest_samvedana_video.php').then(response => response.json())
        ]);
        setVideos(videosResponse);
        setLatestVideo(latestVideoResponse);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error fetching data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="grid grid-col lg:flex-row p-4 gap-4">
      <div className="w-full ">
        {loading ? <p className="text-center text-white">Loading...</p> : <LatestVideo video={latestVideo} />}
      </div>
      <div className="w-full  ">
        {loading ? <p className="text-center text-white">Loading...</p> : <VideoList videos={videos} />}
      </div>
      {error && <p className="text-center text-red-500">{error}</p>}
    </div>
  );
};

export default YouTubePage;
