import React from 'react';

const LatestVideo = ({ video }) => {
  const { youtubeUrl, title } = video || {};

  return (
    <div className="bg-gray-800 p-4 rounded-lg ">
      <h2 className="text-xl sm:text-2xl md:text-3xl text-white font-bold mb-4 text-center">Latest Video</h2>
      {youtubeUrl && (  
        <div className="video-container">
          <iframe
            className="w-full h-96"
            src={`https://www.youtube.com/embed/${youtubeUrl}`}
            title={title || "Latest Video"}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <h2 className="font-bold text-center text-white mt-2">{title}</h2>
        </div>
      )}
    </div>
  );
};

export default LatestVideo;
