import React from 'react';

const LatestVideo = ({ video }) => {
  if (!video || !video[0]) {
    return null;
  }

  const { URL, Name } = video[0];

  // Extract the video ID from the URL
  let videoId;
  if (URL.includes('youtube.com/shorts/')) {
    videoId = URL.split('shorts/')[1];
  } else if (URL.includes('youtube.com/watch?v=')) {
    videoId = URL.split('watch?v=')[1];
  }

  const embedUrl = `https://www.youtube.com/embed/${videoId}`;
  return (
    <div className="bg-gray-800 p-4 rounded-lg">
      <h2 className="text-xl sm:text-2xl md:text-3xl text-white font-bold mb-4 text-center">Latest Video</h2>
      {embedUrl && (
        <div className="video-container">
          <p>hii</p>
          <iframe
            className="w-full h-96"
            src={embedUrl}
            title={Name || "Latest Video"}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <h2 className="font-bold text-center text-white mt-2">{Name}</h2>
        </div>
      )}
    </div>
  );
};

export default LatestVideo;
