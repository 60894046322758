import React from 'react'
import Navbar from '../../../Navbar/Navbar'
import Shippinginfo from '../Undercart/Shippinginfo'
import Contactcomponent from '../../../Contact/Contactcomponent'
import ProductHome from '../Addcart/ProductHome'

function Products() {
  return (
    <div>
      <div><Navbar /></div>
      <div className='mt-20 sm:pt-0'>
        <ProductHome /></div>
      <div><Shippinginfo /></div>
      <div><Contactcomponent /></div>


    </div>
  )
}

export default Products
