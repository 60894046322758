import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

function AddProfesionalAwards() {
    const [formData, setFormData] = useState({
        head: "",
        description: "",
        date: "",
        place: "",
        image: null
    });
    const [isEditing, setIsEditing] = useState(false);
    const [imagePreview, setImagePreview] = useState(null); // To display image preview
    const navigate = useNavigate();
    const location = useLocation();

    // Extract the editId from the query parameters
    const query = new URLSearchParams(location.search);
    const editId = query.get('editId');

    useEffect(() => {
        if (editId) {
            setIsEditing(true);
            // Fetch the existing data to populate the form
            fetchAwardDetails(editId);
        }
    }, [editId]);

    const fetchAwardDetails = async (id) => {
        try {
            const response = await axios.get(`https://samvedanafoundation.com/exe_files/get_professional_award.php?id=${id}`);
            if (response.data) {
                setFormData({
                    head: response.data.Head,
                    description: response.data.Description,
                    date: response.data.Date,
                    place: response.data.Place,
                    image: null // Reset image as it might be handled differently
                });
                setImagePreview(`https://samvedanafoundation.com/exe_files/images/activity_images/${response.data.PhotoPath}`);
            }
        } catch (error) {
            alert("Failed to fetch award details. Please try again.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            setFormData({ ...formData, image: file });
            setImagePreview(URL.createObjectURL(file)); // Set image preview
        } else {
            alert("Please upload an image file.");
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.head || !formData.description || !formData.date || !formData.place) {
            alert("Please fill in all required fields");
            return;
        }

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('head', formData.head);
            formDataToSend.append('description', formData.description);
            formDataToSend.append('date', formData.date);
            formDataToSend.append('place', formData.place);
            if (formData.image) {
                formDataToSend.append('image', formData.image);
            }

            const url = isEditing
                ? 'https://samvedanafoundation.com/exe_files/update_professional_award.php'
                : 'https://samvedanafoundation.com/exe_files/add_professional_award.php';

            const response = await axios.post(url, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            alert("Form submitted successfully");
            navigate('/'); // Navigate back to the main list or another page
        } catch (error) {
            alert("Failed to submit the form. Please try again.");
        }
    }

    return (
        <div className=''>
            <h1 className='text-center text-3xl font-bold'>Professional Awards</h1>

            <div className="heading text-center font-bold text-2xl m-5 text-gray-800">{isEditing ? "Edit Award" : "New Award Post"}</div>
            <form className="editor mx-auto w-10/12 flex flex-col text-gray-800 border border-gray-300 p-4 shadow-lg max-w-2xl" onSubmit={handleSubmit}>
                <input
                    className="title bg-gray-100 border border-gray-300 p-2 mb-4 outline-none"
                    placeholder="Activity Head"
                    type="text"
                    name="head"
                    value={formData.head}
                    onChange={handleChange}
                />
                <textarea
                    className="description bg-gray-100 sec p-3 mb-4 h-60 border border-gray-300 outline-none"
                    placeholder="Describe everything about this post here"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                ></textarea>
                <input
                    type="file"
                    className="title bg-gray-100 border border-gray-300 p-2 mb-4 outline-none"
                    placeholder="Upload Image"
                    accept="image/*"
                    onChange={handleImageChange}
                />
                {imagePreview && (
                    <div className="mb-4">
                        <img 
                            src={imagePreview} 
                            alt="Preview" 
                            style={{ maxWidth: '200px', maxHeight: '200px', objectFit: 'cover' }} 
                        />
                    </div>
                )}
                <input
                    className="title bg-gray-100 border border-gray-300 p-2 mb-4 outline-none"
                    placeholder="Select Date"
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                />
                <input
                    className="title bg-gray-100 border border-gray-300 p-2 mb-4 outline-none"
                    placeholder="Activity Place"
                    type="text"
                    name="place"
                    value={formData.place}
                    onChange={handleChange}
                />
                <div className="buttons flex">
                    <button
                        type="submit"
                        className="btn border border-indigo-500 p-1 px-4 font-semibold cursor-pointer text-gray-200 ml-2 bg-indigo-500"
                    >
                        {isEditing ? "Update" : "Post"}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default AddProfesionalAwards;
