import React, { useState, useEffect } from 'react';

const YouTubeList = () => {
  const [videos, setVideos] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://samvedanafoundation.com/exe_files/list_samvedana_video.php');
        if (!response.ok) {
          throw new Error('Failed to fetch videos');
        }
        const data = await response.json();
        setVideos(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching videos:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  if (loading) {
    return <p className="text-center text-white">Loading videos...</p>;
  }

  if (error) {
    return <p className="text-center text-red-500">{error}</p>;
  }

  return (
    <div className="bg-video mt-8  w-full">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        {videos.slice(0, showAll ? videos.length : 3).map(video => (
          <div key={video.id} className="bg-gray-800 p-4 rounded-lg">
            <div className="video-container">
              <iframe
                src={`https://www.youtube.com/embed/${video.youtubeUrl}`}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                aria-label={video.title}
              ></iframe>
            </div>
            <h2 className="font-bold text-center text-white mt-2">{video.title}</h2>
          </div>
        ))}
      </div>
      {videos.length > 3 && (
        <button
          onClick={toggleShowAll}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline mx-auto block mt-4"
        >
          {showAll ? "See Less" : "See More"}
        </button>
      )}
    </div>
  );
};

export default YouTubeList;
