import React from 'react'
import HomeNewsEvent from './HomeNewsEvent'
import Navbar from '../../Navbar/Navbar'

function NewsEvent() {
  return (
    <div>
    <div><Navbar /></div>
    <div className="mt-8 space-x-5 flex justify-center  sm:pt-0">
      <h1 className="pb-5 text-3xl font-bold  text-green-700 mt-20">Headlines for Social Events and News</h1>
    </div>
    <div className=''><HomeNewsEvent /></div>

   
   

  </div>
  )
}

export default NewsEvent
