import React, { useState } from 'react';
import axios from 'axios';

function AddProduct() {
    const [formData, setFormData] = useState({
        name: '',
        pics: '',          // URL field for existing image
        price: '',
        weight: '',
        description: '',
        image: null        // File field for new image upload
    });
    const [imagePreview, setImagePreview] = useState(null); // For displaying image preview

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            setFormData(prevState => ({ ...prevState, image: file }));
            setImagePreview(URL.createObjectURL(file)); // Set image preview
        } else {
            alert("Please upload a valid image file.");
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('name', formData.name);
            formDataToSend.append('price', formData.price);
            formDataToSend.append('pics', formData.pics);  // URL for existing image
            formDataToSend.append('weight', formData.weight);
            formDataToSend.append('description', formData.description);
            if (formData.image) {
                formDataToSend.append('image', formData.image); // New image upload
            }

            const response = await axios.post('https://samvedanafoundation.com/exe_files/add_product.php', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const result = await response.data;
            if (result.response === 'S') {
                alert(result.msg);
                setFormData({
                    name: '',
                    pics: '',
                    price: '',
                    weight: '',
                    description: '',
                    image: null
                });
                setImagePreview(null); // Clear image preview
            } else {
                throw new Error(result.msg);
            }
        } catch (error) {
            console.error("An error occurred while submitting the form:", error.message);
            alert("Failed to add product: " + error.message);
        }
    };

    return (
        <div>
            <div className="heading text-center font-bold text-2xl m-5 text-gray-800">Product Details</div>
            <form className="editor mx-auto w-10/12 flex flex-col text-gray-800 border border-gray-300 p-4 shadow-lg max-w-2xl" onSubmit={handleSubmit}>
                <input
                    className="title bg-gray-100 border border-gray-300 p-2 mb-4 outline-none"
                    style={{ fontSize: '16px' }}
                    placeholder="Enter Product Name"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                />
                <input
                    className="title bg-gray-100 border border-gray-300 p-2 mb-4 outline-none"
                    style={{ fontSize: '16px' }}
                    placeholder="Enter Product Picture URL"
                    type="text"
                    name="pics"
                    value={formData.pics}
                    onChange={handleChange}
                />
                <input
                    className="title bg-gray-100 border border-gray-300 p-2 mb-4 outline-none"
                    style={{ fontSize: '16px' }}
                    placeholder="Enter Product Price"
                    type="text"
                    name="price"
                    value={formData.price}
                    onChange={handleChange}
                />
                <input
                    className="title bg-gray-100 border border-gray-300 p-2 mb-4 outline-none"
                    style={{ fontSize: '16px' }}
                    placeholder="Enter Product Weight"
                    type="text"
                    name="weight"
                    value={formData.weight}
                    onChange={handleChange}
                />
                <textarea
                    className="description bg-gray-100 sec p-3 mb-4 h-60 border border-gray-300 outline-none"
                    style={{ fontSize: '16px' }}
                    placeholder="Enter Product Description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                ></textarea>
                <input
                    className="title bg-gray-100 border border-gray-300 p-2 mb-4 outline-none"
                    style={{ fontSize: '16px' }}
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                />
                {imagePreview && (
                    <div className="mb-4">
                        <img
                            src={imagePreview}
                            alt="Preview"
                            style={{ maxWidth: '200px', maxHeight: '200px', objectFit: 'cover' }}
                        />
                    </div>
                )}
                <div className="buttons flex">
                    <button
                        type="submit"
                        className="btn border border-indigo-500 p-1 px-4 font-semibold cursor-pointer text-gray-200 ml-2 bg-indigo-500"
                    >
                        Save Product
                    </button>
                </div>
            </form>
        </div>
    );
}

export default AddProduct;
