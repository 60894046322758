import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ListManasvardhan() {
    const [manasvardhanList, setManasvardhanList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchManasvardhanList();
    }, []);

    const fetchManasvardhanList = async () => {
        try {
            const response = await axios.get('https://samvedanafoundation.com/exe_files/list_manasvardhan.php');

            // Handle the case where no data is found
            if (response.data.response === 'E') {
                setManasvardhanList([]); // Set an empty list if no data found
            } else if (Array.isArray(response.data)) {
                setManasvardhanList(response.data); // Set the data if it's a valid array
            } else {
                setManasvardhanList([]);
                setError('Unexpected data format.');
            }
        } catch (error) {
            setError("An error occurred while fetching the Manasvardhan list: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    const deleteManasvardhan = async (index) => {
        try {
            const samvedanaItem = manasvardhanList[index];
            if (!samvedanaItem || !samvedanaItem.ActivityId) {
                throw new Error("Item or ActivityId not found");
            }

            const response = await axios.post(
                'https://samvedanafoundation.com/exe_files/delete_manasvardhan.php',
                JSON.stringify({ Id: samvedanaItem.ActivityId }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );

            if (response.data.response === "D") {
                setManasvardhanList(manasvardhanList.filter((_, i) => i !== index));
                alert("Deleted successfully");
            } else {
                alert("Error: " + (response.data.msg || "Unknown error"));
            }
        } catch (error) {
            console.error("Error deleting:", error.message);
            alert("Error deleting. Please try again.");
        }
    };

    if (loading) {
        return <div className="text-center mt-8">Loading...</div>;
    }

    if (error) {
        return <div className="text-center mt-8">Error: {error}</div>;
    }

    return (
        <div>
            <div className="text-gray-900">
                <div className="p-4 flex">
                    <h1 className="text-3xl">List Manasvardhan</h1>
                </div>
                <div className="px-3 py-4 flex justify-center">
                    {manasvardhanList.length > 0 ? (
                        <table className="w-full text-md bg-white shadow-md rounded mb-4">
                            <thead>
                                <tr>
                                    <th className="text-left p-3 px-5">Head</th>
                                    <th className="text-left p-3 px-5">Extra</th>
                                    <th className="text-left p-3 px-5">Description</th>
                                    <th className="text-left p-3 px-5">Date</th>
                                    <th className="text-left p-3 px-5">Place</th>
                                    <th className="text-left p-3 px-5">Image</th>
                                    <th className="text-left p-3 px-5">Edit/Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {manasvardhanList.map((manasvardhanItem, index) => (
                                    <tr
                                        key={manasvardhanItem.ActivityId}
                                        className={
                                            index % 2 === 0
                                                ? 'border-b hover:bg-orange-100 bg-gray-100'
                                                : 'border-b hover:bg-orange-100'
                                        }
                                    >
                                        <td className="p-3 px-5">{manasvardhanItem.Head}</td>
                                        <td className="p-3 px-5">{manasvardhanItem.ShortDescription}</td>
                                        <td className="p-3 px-5">{manasvardhanItem.Description}</td>
                                        <td className="p-3 px-5">{manasvardhanItem.Date}</td>
                                        <td className="p-3 px-5">{manasvardhanItem.Place}</td>
                                        <td className="p-3 px-5">
                                            <img
                                                src={`https://samvedanafoundation.com/exe_files/images/activity_images/${manasvardhanItem.PhotoPath}`}
                                                alt={manasvardhanItem.Head}
                                                style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover' }}
                                            />
                                        </td>
                                        <td className="p-3 px-5 flex justify-end">
                                            <button
                                                type="button"
                                                onClick={() => alert("edit")} // Add onClick event for edit button
                                                className="mr-3 text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                            >
                                                Edit
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => deleteManasvardhan(index)}
                                                className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className="text-center mt-8">No activities found.</div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ListManasvardhan;
