import React from 'react'
import Navbar from '../Navbar/Navbar'
import Contactcomponent from '../Contact/Contactcomponent'
import OurTeam from './OurTeam'
import Aboutcomponent from './Aboutcomponent'
import './About.css'
import Precident from './Precident'

function About() {
  return (
    <div>
      <div><Navbar /></div>
      <div className='about-compo'>
        <div><Precident /></div>
        <div><OurTeam /></div>
        <div><Contactcomponent />
        </div>
      </div>

    </div>)
}

export default About