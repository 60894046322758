import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ListSamvedana() {
    const [samvedanaList, setSamvedanaList] = useState([]);

    useEffect(() => {
        fetchSamvedanaList();
    }, []);

    const fetchSamvedanaList = async () => {
        try {
            const response = await axios.get('https://samvedanafoundation.com/exe_files/list_samvedana.php');
            setSamvedanaList(response.data);
        } catch (error) {
            console.error("An error occurred while fetching the Samvedana list:", error.message);
        }
    };

    const deleteSamvedana = async (index) => {
        try {
            const samvedanaItem = samvedanaList[index];
            if (!samvedanaItem || !samvedanaItem.ActivityId) {
                throw new Error("Item or ActivityId not found");
            }

            const response = await axios.post('https://samvedanafoundation.com/exe_files/delete_samvedana.php', 
                JSON.stringify({ Id: samvedanaItem.ActivityId }), 
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data.response === "D") {
                setSamvedanaList(samvedanaList.filter((_, i) => i !== index));
                alert("Deleted successfully");
            } else {
                alert("Error: " + (response.data.msg || "Unknown error"));
            }
        } catch (error) {
            console.error("Error deleting:", error.message);
            alert("Error deleting. Please try again.");
        }
    };

    return (
        <div>
            <div className="text-gray-900 ">
                <div className="p-4 flex">
                    <h1 className="text-3xl">List Samvedana</h1>
                </div>
                <div className="px-3 py-4 flex justify-center">
                    <table className="w-full text-md bg-white shadow-md rounded mb-4">
                        <thead>
                            <tr>
                                <th className="text-left p-3 px-5">Head</th>
                                <th className="text-left p-3 px-5">Extra</th>
                                <th className="text-left p-3 px-5">Description</th>
                                <th className="text-left p-3 px-5">Date</th>
                                <th className="text-left p-3 px-5">Place</th>
                                <th className="text-left p-3 px-5">Image</th>
                                <th className="text-left p-3 px-5">Edit/Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {samvedanaList.map((samvedanaItem, index) => (
                                <tr key={samvedanaItem.ActivityId} className={index % 2 === 0 ? 'border-b hover:bg-orange-100 bg-gray-100' : 'border-b hover:bg-orange-100'}>
                                    <td className="p-3 px-5">{samvedanaItem.Head}</td>
                                    <td className="p-3 px-5">{samvedanaItem.ShortDescription}</td>
                                    <td className="p-3 px-5">{samvedanaItem.Description}</td>
                                    <td className="p-3 px-5">{samvedanaItem.Date}</td>
                                    <td className="p-3 px-5">{samvedanaItem.Place}</td>
                                    <td className="p-3 px-5">
                                        <img 
                                            src={`https://samvedanafoundation.com/exe_files/images/activity_images/${samvedanaItem.PhotoPath}`} 
                                            alt={samvedanaItem.Head} 
                                            style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover' }} 
                                        />
                                    </td>
                                    <td className="p-3 px-5 flex justify-end">
                                        <button 
                                            type="button" 
                                            onClick={() => alert("edit")} // Add onClick event for edit button
                                            className="mr-3 text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                        >
                                            Edit
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => deleteSamvedana(index)}
                                            className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default ListSamvedana;
