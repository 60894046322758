import React, { useState } from 'react';
import './DonationHead.css'
import qrCodeImage from '../../img/SamvedhanaQR.jpeg';

function DonationHead() {
  const [showQRCode, setShowQRCode] = useState(false);

  const handleQRCodeToggle = () => {
    setShowQRCode(!showQRCode);
  };
  return (
    <div>
      <header>
        <div class="overlay">
          <h1>Charity</h1>
          <h3>Lend Your Hand: Join us in making a difference through a donation</h3>
          <p>Your contribution, a helping hand, or a day spent with us in service will have a lasting impact on the lives of those we serve. By lending your hand, you become a vital part of our mission to create a compassionate and supportive community. Together, we can achieve meaningful change and build a brighter future for all.</p>
          <br />
          <button onClick={handleQRCodeToggle}>Give Donate</button>
          {showQRCode && (
            <div className="flex justify-center items-center">
              <img src={qrCodeImage} alt="QR Code" />
            </div>

          )}
        </div>
      </header>

    </div>
  )
}

export default DonationHead
