import React from 'react';
import { PiBankBold } from "react-icons/pi";
import SBI from '../../img/HDFC_Bank_Logo.svg.png';
    
function BankDetails() {
  return (
    <div className='sm:flex-row justify-center items-center sm:items-start  w-96 '>
      <div className='flex flex-col items-center sm:mr-8 bg-green-200 w-full h-36'>
        <div className='flex items-center justify-center bg-gray-200 rounded-full w-16 h-16 mt-4 mb-2'>
          <PiBankBold className='text-4xl text-green-900 '  />
        </div>
        <p className='text-center sm:text-left text-green-900 font-bold text-lg'>Bank Accounts</p>
      </div>
      <div className='mt-4 sm:mt-0 pl-4'>
        <img src={SBI} alt="SBI Logo" className='w-full h-24  mx-auto mb-2 sm:mx-0' />
        <p className=' text-left sm:text-left pb-3'>
          <span className='font-semibold'>Name At Bank:</span> HDFC Bank
        </p>
        <p className=' text-left sm:text-left pb-3'>
          <span className='font-semibold'>Branch of Bank:</span>KARMAVEER BHAURAO PATIL CHOWK, SANGLI-416416
        </p>
        <p className=' text-left sm:text-left pb-3'>
          <span className='font-semibold'>Name of A/C Holder:- </span> SAMVEDANA FOUNDATION
        </p>
        <p className='text-left sm:text-left pb-3'>
          <span className='font-semibold'>Account Number:-</span> 50200033213231
        </p>
        <p className='text-left sm:text-left pb-3'>
          <span className='font-semibold'>IFSC Code:-</span> HDFC0000222
        </p>
        <p className='text-left sm:text-left mb-4 pb-3'>
          <span className='font-semibold'>UPI Number:-</span> 98765432112
        </p>
      </div>
    </div>
  );
}

export default BankDetails;
