import React, { useState } from 'react';

const CarasoualSamvedhan = ({ image, head, extra, description }) => {
  const [showDescription, setShowDescription] = useState(false);

  return (
    <div
      className="max-w-sm rounded overflow-hidden shadow-lg cursor-pointer transition-transform transform hover:scale-105 h-full"
      onMouseEnter={() => setShowDescription(true)}
      onMouseLeave={() => setShowDescription(false)}
    >
      <img className="w-full h-96 object-cover" src={image} alt="Card" /> {/* Adjust height as needed */}
      <div className="px-6 py-4 h-full flex flex-col justify-between">
        <div>
          <div className="font-bold text-xl mb-2">{head}</div>
          {showDescription && (
            <>
              <h5 className="text-gray-700 text-base">{extra}</h5>
              <p className="text-gray-700 text-base">{description}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CarasoualSamvedhan;
