import React from 'react'
import { GiReceiveMoney, GiPayMoney } from "react-icons/gi";
import { GrMoney } from "react-icons/gr";

function DonationCard() {
    return (
        <div className='grid grid-cols-1 md:grid-cols-3 gap-8 mt-20 p-5'>
            {/* Donation Section */}
            <div className='bg-gray-200 p-6 rounded-lg border-t-4 border-yellow-500 shadow-lg'>
                <h1 className='text-2xl font-bold mb-4 text-yellow-500'>Donation</h1>
                <p className='text-lg mb-4'>Support our cause with a donation. Every contribution makes a difference.</p>
                <p className='text-3xl flex justify-end'><GiReceiveMoney className='text-yellow-500 w-12 h-12'/></p>
            </div>
            {/* Fund Raising Section */}
            <div className='bg-gray-200 p-6 rounded-lg border-t-4 border-red-500 shadow-lg'>
                <h1 className='text-2xl font-bold mb-4 text-red-500'>Fund Raising</h1>
                <p className='text-lg mb-4'>Join us in raising funds to create positive change in our community.</p>
                <p className='text-3xl flex justify-end'><GrMoney className='text-red-500 w-12 h-12' /></p>
            </div>
            {/* Volunteer Section */}
            <div className='bg-gray-200 p-6 rounded-lg border-t-4 border-green-500 shadow-lg'>
                <h1 className='text-2xl font-bold mb-4 text-green-500'>Volunteer</h1>
                <p className='text-lg mb-4'>Become a volunteer and make a meaningful impact through your time and effort.</p>
                <p className='text-3xl flex justify-end '><GiPayMoney className='text-green-500 w-12 h-12' /></p>
            </div>
        </div>
    )
}

export default DonationCard
