import React from 'react';
import goushala from '../../../img/GaushalaLogo.png';
import GoImg from '../../../img/goushalaAbout.jpg';
import img1 from '../../../img/go-samvardhan.png';
import img2 from '../../../img/nandi-vitran.png';
import img3 from '../../../img/panchgavya-framing.png';
import img4 from '../../../img/training.png';
import img5 from '../../../img/milk-distribution.png';
import img6 from '../../../img/organic-fertilizer.png';

import backgroundImage from '../../../img/bg-gopujan.png';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { IoCallSharp } from "react-icons/io5";

function GaushalaHome() {
    return (
        <div>
            <div className='container mx-auto px-4 pt-8 sm:pt-16' style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 justify-center items-center">
                    <img src={goushala} alt="Gaushala Logo" className='w-full sm:max-w-xs' />
                    <div className=''>
                        <div className="flex items-center mb-4">
                            <FaMapMarkerAlt className="text-orange-900 sm:text-xl" />
                            <p className="ml-2 sm:text-base font-bold text-orange-900">Budhgaon - Kupwad Rd, Bisur, Maharashtra 416304</p>
                        </div>
                        <div className="flex items-center">
                            <IoCallSharp className="text-orange-900 sm:text-xl" />
                            <p className="ml-2 sm:text-base font-bold text-orange-900">+91 9423001415</p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col sm:flex-row items-center '>
                    <div className='w-full sm:w-1/2 mb-8 sm:mb-0'>
                        <img src={GoImg} alt="Gaushala" className='w-full rounded-lg shadow-lg' />
                    </div>
                    <div className='w-full sm:w-1/2 p-8 rounded-lg '>
                        <h1 className='text-3xl font-bold mb-4 text-orange-900'>Samvedana Gaushala Sangli</h1>
                        <p className='text-gray-900 text-lg font-semibold text-left'>
                            Samvedna Foundation, Sangli runs Samvedna Gaushala in Budhgaon for the past 5 years. Located on a 2-acre land on the Kupwad-Budhgaon road in Sangli, the Gaushala currently houses 50 cows. Experienced individuals have been appointed to take care of the cows and ensure their daily routine is followed properly. In case of illness or any other issues, the gaushala has doctors on call. Gaushala seva is considered as service to God and the organization is dedicated to this noble cause. The main objective of the organization is cow conservation. The organization also provides proper treatment to injured and sick cows. The organization has taken up the task of creating awareness among people for cow conservation. We, as an organization, believe in promoting the authenticity of cow products and have been successful in this endeavor. We also produce dairy products like curd, buttermilk, ghee, and soap from cow milk and cow urine.
                        </p>
                    </div>
                </div>
            </div>
            <div className='container mx-auto px-4 mt-8'>
                <h1 className='text-2xl sm:text-3xl font-bold mb-4 text-orange-900 text-center'>Samvedana Gaushala Various Activities</h1>
                <div className='grid grid-cols-2 sm:grid-cols-3 gap-8'>
                    <div className='text-center'>
                        <img src={img1} alt="Go Samvardhan" className='w-84 mx-auto' />
                        <p className='text-gray-900 text-lg font-semibold mt-4'>Go Samvardhan</p>
                    </div>
                    <div className='text-center'>
                        <img src={img2} alt="Nandi Distribution" className='w-84 mx-auto' />
                        <p className='text-gray-900 text-lg font-semibold mt-4'>Nandi Distribution</p>
                    </div>
                    <div className='text-center'>
                        <img src={img3} alt="Panchgavya Farming" className='w-84 mx-auto' />
                        <p className='text-gray-900 text-lg font-semibold mt-4'>Panchgavya Farming</p>
                    </div>
                    <div className='text-center'>
                        <img src={img4} alt="Training" className='w-84 mx-auto' />
                        <p className='text-gray-900 text-lg font-semibold mt-4'>Training</p>
                    </div>
                    <div className='text-center'>
                        <img src={img5} alt="Satvik Milk Distribution" className='w-84 mx-auto' />
                        <p className='text-gray-900 text-lg font-semibold mt-4'>Satvik Milk Distribution</p>
                    </div>
                    <div className='text-center'>
                        <img src={img6} alt="Organic Fertilizer" className='w-84 mx-auto' />
                        <p className='text-gray-900 text-lg font-semibold mt-4'>Organic Fertilizer</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GaushalaHome;
