import React from 'react';
import Contactcomponent from '../Contact/Contactcomponent';
import HomeSamvedhana from '../Aminpannel/Samvedana/HomeSamvedhana';

import HomeAbout from './HomeAbout/HomeAbout';
import ProductHome from '../Product/ProductNavbar/Addcart/ProductHome';
import Navbar from '../Navbar/Navbar';
import Contactform from '../Aminpannel/Samvedana/Contactform';
import GheeHomeCard from '../Product/ProductHome/GheeHomeCard';
import DhoopCard from '../Product/ProductHome/DhoopCard';
import DiyaCard from '../Product/ProductHome/DiyaCard';
import PanchgavyCard from '../Product/ProductHome/PanchgavyCard';
import YouTubePage from '../Aminpannel/Manasvardhan/YouTube/Display/YouTubePage';


function Home() {
    return (
        <div>
            <div><Navbar/></div>
            <div><HomeAbout/></div>
            {/* <div><Aboutcomponent /></div> */}
            {/* <DhoopCard/>
            <DiyaCard />
            <PanchgavyCard /> */}
            {/* <div><SampleActivity/></div> */}
              {/* <div className=''><BodyWork/></div> */}
            <div><ProductHome /></div>
            <div><YouTubePage /></div>

            <div><HomeSamvedhana /></div>
            <div><Contactform/></div>

            <div><Contactcomponent /></div>
        </div>
    );
}

export default Home;
