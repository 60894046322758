import React from 'react';
import img1 from '../../../../img/truck.png';
import img2 from '../../../../img/customer-service.png';
import img3 from '../../../../img/secure-payment.png';
import img4 from '../../../../img/easy-return.png';

function Shippinginfo() {
  return (
    <div className="container mx-auto py-8">
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-8">
        <div className="flex flex-col items-center justify-center w-full px-4">
          <img src={img1} className="w-20 h-20 mb-4" alt="Free Shipping" />
          <h4 className="text-lg font-semibold mb-2">Free Shipping</h4>
          <p className="text-gray-700 text-center">We offer free shipping all over India. Simply buy now and enjoy free shipping.</p>
        </div>

        <div className="flex flex-col items-center justify-center w-full px-4">
          <img src={img2} className="w-20 h-20 mb-4" alt="Customer Support" />
          <h4 className="text-lg font-semibold mb-2">Customer Support</h4>
          <p className="text-gray-700 text-center">We're here to help 24/7, 365 days a year. Reach out to us anytime!</p>
        </div>

        <div className="flex flex-col items-center justify-center w-full px-4">
          <img src={img3} className="w-20 h-20 mb-4" alt="Secure Payment" />
          <h4 className="text-lg font-semibold mb-2">100% Secure Payment</h4>
          <p className="text-gray-700 text-center">Our payment system is 100% secure and safe. Pay with confidence.</p>
        </div>

        <div className="flex flex-col items-center justify-center w-full px-4">
          <img src={img4} className="w-20 h-20 mb-4" alt="Easy Returns" />
          <h4 className="text-lg font-semibold mb-2">Easy Returns</h4>
          <p className="text-gray-700 text-center">We care about your happiness. Enjoy hassle-free returns anytime.</p>
        </div>
      </div>
    </div>
  );
}

export default Shippinginfo;
