import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../img/Samvdhana logo.png';
import AddCustomer from '../Aminpannel/Customer/AddCustomer';
import AddProduct from '../Aminpannel/Product/AddProduct';
import AddGaushala from '../Aminpannel/Gaushala/AddGaushala';
import AddSamvedana from '../Aminpannel/Samvedana/AddSamvedana';
import ListSamvedana from '../Aminpannel/Samvedana/ListSamvedana';
import ListGaushala from '../Aminpannel/Gaushala/ListGaushala';
import AddManasvardhan from '../Aminpannel/Manasvardhan/AddManasvardhan';
import ListManasvardhan from '../Aminpannel/Manasvardhan/ListManasvardhan';
import ListProduct from '../Aminpannel/Product/ListProduct';
import ListCustomer from '../Aminpannel/Customer/ListCustomer';
import AddSocialAwards from '../Aminpannel/Awards/Social/AddSocialAwards';
import ListSocialAwards from '../Aminpannel/Awards/Social/ListSocialAwards';
import AddProfesionalAwards from '../Aminpannel/Awards/Professional/AddProfesionalAwards';
import ListProfesionalAwards from '../Aminpannel/Awards/Professional/ListProfesionalAwards';
import ListVideo from '../Aminpannel/Samvedana/YouTube/ListVIdeo';
import AddNews from '../Aminpannel/News/News/AddNews';
import ListNews from '../Aminpannel/News/News/ListNews';
import AddSocial from '../Aminpannel/News/Social/AddSocial';
import ListSocial from '../Aminpannel/News/Social/ListSocial';
import AddVideoSam from '../Aminpannel/Samvedana/YouTube/AddVideoSam';
import AddHomePage from '../Aminpannel/HomePage/AddHomePage';
import ListHomePage from '../Aminpannel/HomePage/ListHomePage';

const MultiSectionForm = () => {
  const [activeSection, setActiveSection] = useState('');
  const [actionType, setActionType] = useState('');

  const handleSectionChange = (sectionId) => {
    setActiveSection(sectionId);
    setActionType('');
  };

  const handleActionTypeChange = (type) => {
    setActionType(type);
  };

  const isSamvedanaSection = (section) => {
    return section === 'section1';
  };

  return (
    <div>
      <div className="container mx-auto px-4 py-8">
        <div className='flex items-center justify-evenly '>
          <img src={logo} className="w-24 h-24 mb-4" alt="Logo" />
          <h1 className='text-2xl font-bold text-green-700 mb-2'>Admin Panel</h1>
          <Link to='/'><button className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded'>Home Page</button></Link>
        </div>
        <div className="flex flex-wrap justify-center mb-4" style={{ gap: '2rem' }}>
          <div className="w-full md:w-1/2 lg:w-1/4 mb-4 md:mb-0">
            <select
              value={activeSection}
              onChange={(e) => handleSectionChange(e.target.value)}
              className="w-full p-2 border rounded-md "
            >
              <option value="">Select Section</option>
              <option value="section1">Samvedana</option>
              <option value="section2">Gaushala</option>
              <option value="section3">Manasvardhan</option>
              <option value="section4">Product</option>
              <option value="section5">Customer</option>
              <option value="section6">News</option>
              <option value="section7">Social</option>
              <option value="section8">Professional Awards</option>
              <option value="section9">Social Awards</option>
              <option value="section10">Home Page</option>

            </select>
          </div>

          {activeSection && (
            <div className="w-full md:w-1/2 lg:w-1/4">
              <select
                value={actionType}
                onChange={(e) => handleActionTypeChange(e.target.value)}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="">Select Action</option>
                <option value="add">Add</option>
                <option value="list">List</option>
                {isSamvedanaSection(activeSection) && (
                  <>
                    <option value="add video">Add Video</option>
                    <option value="list video">List Video</option>
                  </>
                )}
              </select>
            </div>
          )}
        </div>

        <div className="flex flex-wrap">
          {activeSection === 'section1' && actionType === 'add' && (
            <div className="w-full">
              <AddSamvedana />
            </div>
          )}
          {activeSection === 'section1' && actionType === 'list' && (
            <div className="w-full">
              <ListSamvedana />
            </div>
          )}
          {activeSection === 'section1' && actionType === 'add video' && (
            <div className="w-full">
              <AddVideoSam />
            </div>
          )}
          {activeSection === 'section1' && actionType === 'list video' && (
            <div className="w-full">
              <ListVideo />
            </div>
          )}
          {activeSection === 'section2' && actionType === 'add' && (
            <div className="w-full">
              <AddGaushala />
            </div>
          )}
          {activeSection === 'section2' && actionType === 'list' && (
            <div className="w-full">
              <ListGaushala />
            </div>
          )}
          {activeSection === 'section3' && actionType === 'add' && (
            <div className="w-full">
              <AddManasvardhan />
            </div>
          )}
          {activeSection === 'section3' && actionType === 'list' && (
            <div className="w-full">
              <ListManasvardhan />
            </div>
          )}
          {activeSection === 'section4' && actionType === 'add' && (
            <div className="w-full">
              <AddProduct />
            </div>
          )}
          {activeSection === 'section4' && actionType === 'list' && (
            <div className="w-full">
              <ListProduct />
            </div>
          )}
          {activeSection === 'section5' && actionType === 'list' && (
            <div className="w-full">
              <ListCustomer />
            </div>
          )}
          {activeSection === 'section6' && actionType === 'add' && (
            <div className="w-full">
              <AddNews />
            </div>
          )}
          {activeSection === 'section6' && actionType === 'list' && (
            <div className="w-full">
              <ListNews />
            </div>
          )}
          {activeSection === 'section7' && actionType === 'add' && (
            <div className="w-full">
              <AddSocial />
            </div>
          )}
          {activeSection === 'section7' && actionType === 'list' && (
            <div className="w-full">
              <ListSocial />
            </div>
          )}
          {activeSection === 'section8' && actionType === 'add' && (
            <div className="w-full">
              <AddProfesionalAwards />
            </div>
          )}
          {activeSection === 'section8' && actionType === 'list' && (
            <div className="w-full">
              <ListProfesionalAwards />
            </div>
          )}
          {activeSection === 'section9' && actionType === 'add' && (
            <div className="w-full">
              <AddSocialAwards />
            </div>
          )}
          {activeSection === 'section9' && actionType === 'list' && (
            <div className="w-full">
              <ListSocialAwards />
            </div>
          )}
          {activeSection === 'section10' && actionType === 'add' && (
            <div className="w-full">
              <AddHomePage />
            </div>
          )}
          {activeSection === 'section10' && actionType === 'list' && (
            <div className="w-full">
              <ListHomePage />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiSectionForm;
