import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const ProductForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { productName, weight, price } = location.state || {};

  // Step tracking
  const [step, setStep] = useState(1);

  // Form data
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        'https://samvedanafoundation.com/exe_files/create_customer.php',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      // Check if the response is in the expected format
      if (response.data && response.data.msg) {
        if (response.data.response === 'S') {
          alert('Registration Successful');
          navigate('/order-page');
        } else {
          alert('Failed to submit the form');
        }
      } else {
        // Handle unexpected response format
        alert('Unexpected response format: ' + JSON.stringify(response.data));
      }
    } catch (error) {
      console.error("An error occurred while submitting the form:", error.message);
      alert("Failed to submit the form");
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          step === 1 ? setStep(2) : handleSubmit(e); // Move to the next step or submit
        }}
        className="bg-white p-8 rounded shadow-md w-full max-w-md"
      >
        <h2 className="text-2xl font-bold mb-6 text-gray-800">
          {step === 1 ? 'Customer Details' : 'Shipping Address Details'}
        </h2>

        {/* Display product details */}
        {productName && step === 1 && (
          <div className="mb-4">
            <p className="text-gray-700">
              <strong>Product:</strong> {productName}
            </p>
            <p className="text-gray-700">
              <strong>Weight:</strong> {weight}
            </p>
            <p className="text-gray-700">
              <strong>Price:</strong> Rs.{price.toFixed(2)}
            </p>
          </div>
        )}

        {/* Customer Details Section */}
        {step === 1 && (
          <>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2" htmlFor="name">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2" htmlFor="mobile">
                Mobile Number
              </label>
              <input
                type="tel"
                id="mobile"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded"
                required
              />
            </div>
          </>
        )}

        {/* Shipping Details Section */}
        {step === 2 && (
          <>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2" htmlFor="line1">
                Address Line 1
              </label>
              <input
                type="text"
                id="line1"
                name="line1"
                value={formData.line1}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2" htmlFor="line2">
                Address Line 2
              </label>
              <input
                type="text"
                id="line2"
                name="line2"
                value={formData.line2}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2" htmlFor="city">
                City
              </label>
              <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2" htmlFor="state">
                State
              </label>
              <input
                type="text"
                id="state"
                name="state"
                value={formData.state}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded"
                required
              />
            </div>
          </>
        )}

        <button
          type="submit"
          className="w-full py-2 px-4 bg-green-500 text-white rounded hover:bg-green-700 transition-transform hover:scale-105"
        >
          {step === 1 ? 'Next' : 'Submit'}
        </button>
      </form>
    </div>
  );
};

export default ProductForm;
