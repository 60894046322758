import React from 'react';
// import img from '../../../img/contact-us-3483601-2912018.png';
import { MdLocalPhone, MdOutlineMail } from 'react-icons/md';
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";


function Contactform() {
    return (
        <div className=' p-8 -mb-20'>
            <div className="grid sm:grid-cols-2 items-center gap-20 my-6 mx-auto max-w-4xl bg-white text-[#333] font-[sans-serif] ">
                <div>
                    <div className="mt-12">
                        <h2 className="text-lg text-orange-700 font-extrabold">Call</h2>
                        <ul className="mt-3">
                            <li className="flex items-center">
                                <div className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                                    <p className="text-red-700 font-bold" aria-label="Phone Icon"><MdLocalPhone /></p>
                                </div>
                                <a href="tel:+919423001415" className="text-gray-900 text-sm ml-3">
                                    <small className="block font-bold">Call</small>
                                    <strong className="text-lg font-bold">+91 9423001415</strong>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="mt-12">
                        <h2 className="text-lg text-orange-700 font-extrabold">Email</h2>
                        <ul className="mt-3">
                            <li className="flex items-center">
                                <div className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                                    <p className="text-red-700 font-bold" aria-label="Mail Icon"><IoMdMail /></p>
                                </div>
                                <a href="mailto:victorent197@gmail.com" className="text-text-gray-900 text-sm ml-3">
                                    <small className="block font-bold">Mail</small>
                                    <strong className="text-lg font-bold">samvedanafoundation.sangli@gmail.com</strong>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="mt-12">
                        <h2 className="text-lg text-orange-700 font-extrabold">Address</h2>
                        <ul className="mt-3">
                            <li className="flex items-center">
                                <div className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                                    <span className="text-red-700 font-bold" aria-label="Mail Icon"><FaLocationDot /></span>
                                </div>
                                <a className="text-text-gray-900 text-sm ml-3">
                                    <small className="block font-bold">Address</small>
                                    <strong className="text-lg font-bold">Budhgaon - Kupwad Rd, Bisur, Maharashtra 416304</strong>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="flex space-x-4 mb-4 mt-4">
                        <a href="tel:+919423001415" className="bg-orange-500 hover:bg-orange-800 text-white py-2 px-4 rounded">Call</a>
                        <a href="https://maps.app.goo.gl/6k9WWK5XFWF4geiH9" target="_blank" rel="noopener noreferrer" className="bg-orange-500 hover:bg-orange-800 text-white py-2 px-4 rounded">Get Direction</a>
                    </div>

                </div>
                <form className="ml-auto space-y-4">
                    <h1 className="text-orange-700 font-bold text-xl">Enquire now</h1>
                    <input type="text" placeholder="Name" className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm outline-red-700" />
                    <input type="email" placeholder="Email" className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm outline-red-700" />
                    <input type="text" placeholder="Subject" className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm outline-red-700" />
                    <textarea placeholder="Message" rows="6" className="w-full rounded-md px-4 bg-gray-100 text-sm pt-3 outline-red-700"></textarea>
                    <button type="submit" className="text-white bg-orange-500 hover:bg-orange-800 font-semibold rounded-md text-sm px-4 py-3 w-full">Send</button>
                </form>
            </div>
        </div>
    );


}

export default Contactform;
