import React from 'react';
import donation from '../../img/Donation.png'; 
import BankDetails from './BankDetails'; 
import UPIPayment from './UPIPayment';

function DonationPayment() {
  return (
    <div className='flex flex-col md:flex-row justify-center items-center md:items-start'>

      <div className='md:mr-8 mb-2 md:mb-0'>
        <img src={donation} alt="" className='w-full md:w-auto'/>
        <p class="text-2xl text-yellow-600 text-center font-bold text-gray-800">Supporting a cause? Donate now</p>
      </div>

      <div className='mb-4 md:mb-0'>
        <UPIPayment />
      </div>

      <div className=''>
        <BankDetails />
      </div>
      
    </div>
  );
}

export default DonationPayment;
