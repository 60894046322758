import React, { useState } from 'react';
import axios from 'axios';

function AddHomePage() {
    const [formData, setFormData] = useState({
        image: null
    });

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            setFormData({ ...formData, image: file });
        } else {
            alert("Please upload an image file.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('image', formData.image);

            const response = await axios.post('https://samvedanafoundation.com/exe_files/add_home_banner.php', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log(response.data);
            alert("Form submitted successfully");
        } catch (error) {
            console.error("An error occurred while submitting the form:", error.message);
            alert("Failed to submit the form");
        }
    };

    return (
        <div className=''>
            <h1 className='text-center text-3xl font-bold'>Home Page</h1>

            <div className="heading text-center font-bold text-2xl m-5 text-gray-800">New Home Images</div>
            <form className="editor mx-auto w-10/12 flex flex-col text-gray-800 border border-gray-300 p-4 shadow-lg max-w-2xl" onSubmit={handleSubmit}>
                <input type="file" className="title bg-gray-100 border border-gray-300 p-2 mb-4 outline-none" placeholder="Upload Image" accept="image/*" onChange={handleImageChange} />
                <div className="buttons flex">
                    <button type="submit" className="btn border border-indigo-500 p-1 px-4 font-semibold cursor-pointer text-gray-200 ml-2 bg-indigo-500">Post</button>
                </div>
            </form>
        </div>
    );
}

export default AddHomePage;
