import React from 'react'
import { FaFacebook, FaWhatsapp, FaInstagram, FaLinkedin, FaTwitter, FaEnvelope } from 'react-icons/fa';
import president from '../../img/Ronak-shah.jpg';

function OurTeam() {
    return (
        <div className="py-6 dark:bg-gray-800 dark:text-gray-100">
            <div className="container flex flex-col items-center justify-center p-4 mx-auto space-y-8 sm:p-10">

                <h1 className="text-4xl font-bold leadi text-center text-green-700 sm:text-5xl mb-5">Our team</h1>
                <p className="max-w-2xl text-center dark:text-gray-400"></p>
                {/* <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"> */}

                <div className="flex flex-col justify-center items-center text-center">
                    <img alt="" className="self-center flex-shrink-0 w-24 h-24 -mt-12 bg-center bg-cover rounded-full dark:bg-gray-500" src={president} />
                    <div className="flex-1 my-4">
                        <p className="text-xl font-semibold leadi">Sh.Ronak Rajnikant Shah</p>
                        <p>President</p>
                    </div>
                    <div className="flex items-center justify-center p-3 space-x-3 border-t-2">
                        <a rel="noopener noreferrer" href="mailto:sail1415@gmail.com" title="Email" className="text-gray-400 hover:text-green-500">
                            <FaEnvelope />
                        </a>
                        <a rel="noopener noreferrer" href="https://twitter.com/Ronaksh46083798" title="Twitter" className="text-gray-400 hover:text-blue-500">
                            <FaTwitter />
                        </a>
                        <a rel="noopener noreferrer" href="https://www.linkedin.com/in/ronak-shah-722a7324/?originalSubdomain=in" title="LinkedIn" className="text-gray-400 hover:text-blue-500">
                            <FaLinkedin />
                        </a>
                        <a rel="noopener noreferrer" href="https://www.facebook.com/ronak.sudarshan" title="Facebook" className="text-gray-400 hover:text-blue-500">
                            <FaFacebook />
                        </a>
                        <a rel="noopener noreferrer" href="https://wa.me/919423001415" title="WhatsApp" className="text-gray-400 hover:text-green-500">
                            <FaWhatsapp />
                        </a>
                        <a rel="noopener noreferrer" href="https://www.instagram.com/ronak_shah1415/" title="Instagram" className="text-gray-400 hover:text-pink-600">
                            <FaInstagram />
                        </a>
                    </div>

                </div>

                {/* </div> */}
                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8 justify-center">
                    <div class="flex flex-col justify-center m-4 text-center">
                        {/* <img alt="" class="self-center flex-shrink-0 w-24 h-24 mb-4 bg-center bg-cover rounded-full dark:bg-gray-500" src="https://source.unsplash.com/100x100/?portrait?1" /> */}
                        <p class="text-xl font-semibold leading">Smt.Poonam Ronak Shah</p>
                        <p class="dark:text-gray-400">Vice President</p>
                    </div>
                    <div class="flex flex-col justify-center m-4 text-center">
                        {/* <img alt="" class="self-center flex-shrink-0 w-24 h-24 mb-4 bg-center bg-cover rounded-full dark:bg-gray-500" src="https://source.unsplash.com/100x100/?portrait?1" /> */}
                        <p class="text-xl font-semibold leading">Smt.Ushaben Rajnikant Shah</p>
                        <p class="dark:text-gray-400">Secretary</p>
                    </div>
                    <div class="flex flex-col justify-center m-4 text-center">
                        {/* <img alt="" className="self-center flex-shrink-0 w-24 h-24 mb-4 bg-center bg-cover rounded-full dark:bg-gray-500" src="https://source.unsplash.com/100x100/?portrait?1" /> */}
                        <p className="text-xl font-semibold leadi">Ms.Smriti Ronak Shah</p>
                        <p className="dark:text-gray-400">Treasurer </p>
                    </div>
                    <div class="flex flex-col justify-center m-4 text-center">
                        {/* <img alt="" className="self-center flex-shrink-0 w-24 h-24 mb-4 bg-center bg-cover rounded-full dark:bg-gray-500" src="https://source.unsplash.com/100x100/?portrait?1" /> */}
                        <p className="text-xl font-semibold leadi">Sh.Mehul Vasa </p>
                        <p className="dark:text-gray-400">Member</p>
                    </div>
                    <div class="flex flex-col justify-center m-4 text-center">
                        {/* <img alt="" className="self-center flex-shrink-0 w-24 h-24 mb-4 bg-center bg-cover rounded-full dark:bg-gray-500" src="https://source.unsplash.com/100x100/?portrait?1" /> */}
                        <p className="text-xl font-semibold leadi"> Sh. Mohan Krishna Chaudhary</p>
                        <p className="dark:text-gray-400">Member </p>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default OurTeam