import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import Shippinginfo from '../ProductNavbar/Undercart/Shippinginfo';
import { Link } from 'react-router-dom';

// Import images
import img1 from '../../../img/1.jpg';
import img2 from '../../../img/2.jpg';
import img3 from '../../../img/3.jpg';

const slides = [
  { img: img1 },
  { img: img2 },
  { img: img3 }
];

function DhoopCard() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [weight] = useState('14 PCS');  // Static weight
  const [price] = useState(80.00);  // Static price

  const sliderRef = useRef(null);

  useEffect(() => {
    const slideInterval = 3000; // Set slide interval to 3 seconds (3000 milliseconds)

    const intervalId = setInterval(() => {
      if (sliderRef.current) {
        const nextIndex = (currentIndex + 1) % slides.length;
        setCurrentIndex(nextIndex);
        sliderRef.current.slickGoTo(nextIndex);
      }
    }, slideInterval);

    return () => clearInterval(intervalId);
  }, [currentIndex]);

  return (
    <div className='bg-gray-100 shadow-lg p-6 md:p-10 lg:p-16 max-w-full mx-auto'>
      <h1 className='text-3xl text-center font-bold text-black mb-6'>Add To Cart</h1>
      <div className='flex flex-col md:flex-row justify-around items-center md:items-start'>
        <div className='bg-white shadow-lg rounded-lg overflow-hidden mb-6 md:mb-0 md:mr-6 w-full md:w-2/6'>
          <Slider ref={sliderRef}>
            {slides.map((slide, index) => (
              <img key={index} src={slide.img} alt={`Slide ${index}`} className='w-full' />
            ))}
          </Slider>
        </div>
        <div className="flex flex-col justify-center items-start h-[50vh] md:w-1/3">
          <h1 className="text-3xl font-bold text-green-900 mb-2">COW DUNG DHOOP</h1>
          <p className='text-gray-600 mb-4'>Dhoop sticks made out of cow dung which is used for Pooja, contributing to bliss and peace of the surroundings as well as acting as a natural deterrent to mosquitoes.</p>
          <p className="text-xl text-gray-600 font-medium">Pieces: <span className="font-semibold text-black">{weight}</span></p>
          <p className="text-xl text-gray-600 font-medium">Price: Rs. <span className="font-semibold text-black">{price.toFixed(2)}</span></p>
          <Link 
            to="/productform" 
            state={{ 
              productName: 'Cow Dung Dhoop', 
              weight, 
              price 
            }}
          >
            <button className="text-white w-48 mt-8 bg-green-500 hover:bg-green-700 transition-transform hover:scale-105 px-4 py-2 rounded">
              Buy Now
            </button>
          </Link>
        </div>
      </div>
      <div className='mt-6 p-4'>
        <h1 className='text-xl text-left font-bold text-green-800 mb-4'>Description</h1>
        <ul className='space-y-2 text-gray-700'>
          <li>
            <span className='font-semibold'>✔ REMOVES MENTAL STRESS:</span> Burning incense made of pure organic dhoop batti cow dung removes mental stress and gives peace to the mind. If you stand for some time every day in its aroma then it will calm your mind and mind and will also work to reduce stress.
          </li>
          <li>
            <span className='font-semibold'>✔ KILLS THE BACTERIA & PURIFY ENVIRONMENT:</span> If you use desi cow dung incense sticks, then camphor, jatamasi, guggul, etc. found inside it, the bacteria present in the environment of your house. Works to eliminate and keeps you and the environment healthy.
          </li>
          <li>
            <span className='font-semibold'>✔ REMOVES NEGATIVE ENERGY:</span> There is also an advantage of using dung incense sticks as compared to a common incense stick, that it is also very useful in removing any kind of negative energy present in the house and the smoke of dung incense does not harm anyone.
          </li>
          <li>
            <span className='font-semibold'>✔ MAKES THE ATTRACTIVE ATMOSPHERE:</span> Cow Dung Dhoop Batti Due to being made from a mixture of things like frankincense, guggul in the dung incense stick, when you burn it, it absorbs the foul smell present in the surroundings and fills the aroma in the atmosphere. In a way, it makes the atmosphere attractive.
          </li>
          <li>
            <span className='font-semibold'>✔ REMOVES PLANETARY EFFECTS:</span> The astrologer also says that cow dung incense sticks are very suitable to remove the effects of the planet. If any planet is having a bad influence on you, then buy incense sticks made of pure cow dung from the market or online and burn it daily in your house. The bad effects of the planet will go away on its own.
          </li>
        </ul>
      </div>
      <Shippinginfo />
    </div>
  );
}

export default DhoopCard;
