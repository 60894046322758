import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ListGaushala() {
    const [gaushalaList, setGaushalaList] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchGaushalaList();
    }, []);

    const fetchGaushalaList = async () => {
        try {
            const response = await axios.get('https://samvedanafoundation.com/exe_files/list_gaushala.php');
            console.log("API response:", response.data); // Log the response to see its structure

            if (response.data.response === "E") {
                // Handle the case where no data is found
                setError(response.data.msg);
                setGaushalaList([]); // Ensure the list is empty
            } else if (Array.isArray(response.data)) {
                // Handle the case where data is returned
                setGaushalaList(response.data);
                setError(''); // Clear any previous error message
            } else {
                console.error("Unexpected response format:", response.data);
                setError('Unexpected response format');
                setGaushalaList([]); // Ensure the list is empty
            }
        } catch (error) {
            console.error("An error occurred while fetching the Gaushala list:", error.message);
            setError('Error fetching data. Please try again.');
            setGaushalaList([]); // Ensure the list is empty
        }
    };

    const deleteGaushala = async (index) => {
        try {
            const samvedanaItem = gaushalaList[index];
            if (!samvedanaItem || !samvedanaItem.ActivityId) {
                throw new Error("Item or ActivityId not found");
            }

            const response = await axios.post('https://samvedanafoundation.com/exe_files/delete_gaushala.php', 
                JSON.stringify({ Id: samvedanaItem.ActivityId }), 
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data.response === "D") {
                setGaushalaList(gaushalaList.filter((_, i) => i !== index));
                alert("Deleted successfully");
            } else {
                alert("Error: " + (response.data.msg || "Unknown error"));
            }
        } catch (error) {
            console.error("Error deleting:", error.message);
            alert("Error deleting. Please try again.");
        }
    };

    return (
        <div>
            <div className="text-gray-900">
                <div className="p-4 flex">
                    <h1 className="text-3xl">List Gaushala</h1>
                </div>
                {error && <div className="px-3 py-4 text-red-500">{error}</div>}
                <div className="px-3 py-4 flex justify-center">
                    <table className="w-full text-md bg-white shadow-md rounded mb-4">
                        <thead>
                            <tr>
                                <th className="text-left p-3 px-5">Head</th>
                                <th className="text-left p-3 px-5">Extra</th>
                                <th className="text-left p-3 px-5">Description</th>
                                <th className="text-left p-3 px-5">Date</th>
                                <th className="text-left p-3 px-5">Place</th>
                                <th className="text-left p-3 px-5">Image</th>
                                <th className="text-left p-3 px-5">Edit/Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {gaushalaList.length > 0 ? (
                                gaushalaList.map((gaushalaItem, index) => (
                                    <tr key={gaushalaItem.ActivityId} className={index % 2 === 0 ? 'border-b hover:bg-orange-100 bg-gray-100' : 'border-b hover:bg-orange-100'}>
                                        <td className="p-3 px-5">{gaushalaItem.Head}</td>
                                        <td className="p-3 px-5">{gaushalaItem.ShortDescription}</td>
                                        <td className="p-3 px-5">{gaushalaItem.Description}</td>
                                        <td className="p-3 px-5">{gaushalaItem.Date}</td>
                                        <td className="p-3 px-5">{gaushalaItem.Place}</td>
                                        <td className="p-3 px-5">
                                            <img 
                                                src={`https://samvedanafoundation.com/exe_files/images/activity_images/${gaushalaItem.PhotoPath}`} 
                                                alt={gaushalaItem.Head} 
                                                style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover' }} 
                                            />
                                        </td>
                                        <td className="p-3 px-5 flex justify-end">
                                            <button 
                                                type="button" 
                                                onClick={() => alert("edit")} // Placeholder for edit functionality
                                                className="mr-3 text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                            >
                                                Edit
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => deleteGaushala(index)}
                                                className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" className="p-3 px-5 text-center">No Gaushala data available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default ListGaushala;
