import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import UpperNav from './UpperNav';
import logo from '../../img/Samvdhana logo.png';

function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className="fixed top-0 left-0 right-0 z-50 bg-white shadow-lg">
            <UpperNav />
            <nav className="container mx-auto flex items-center justify-between  px-6 md:px-12">
                <div className="flex items-center">
                    <img src={logo} alt="Samvdhana Logo" className="h-24 w-auto" />
                </div>
                <div className="md:hidden">
                    <button
                        onClick={toggleMobileMenu}
                        className="text-gray-800 hover:text-red-600 focus:outline-none"
                    >
                        <svg className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            {menuOpen ? (
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            ) : (
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            )}
                        </svg>
                    </button>
                </div>
                <div className={`md:flex ${menuOpen ? 'block' : 'hidden'} w-full md:w-auto`}>
                    <ul className="flex flex-col md:flex-row md:items-center md:space-x-6 mt-4 md:mt-0">
                        <li>
                            <Link to="/" className="block py-2 text-gray-800 hover:text-violet-800 text-lg font-semibold">
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link to="/about" className="block py-2 text-gray-800 hover:text-violet-600 text-lg font-semibold">
                                About Us
                            </Link>
                        </li>
                        <li>
                            <Link to="/activitygaushala" className="block py-2 text-gray-800 hover:text-violet-600 text-lg font-semibold">
                                Gaushala
                            </Link>
                        </li>
                        <li>
                            <Link to="/activitymanasvardhan" className="block py-2 text-gray-800 hover:text-violet-600 text-lg font-semibold">
                                Manasvardhan
                            </Link>
                        </li>
                        <li>
                            <Link to="/news" className="block py-2 text-gray-800 hover:text-violet-600 text-lg font-semibold">
                                News & Events
                            </Link>
                        </li>
                        <li>
                            <Link to="/award" className="block py-2 text-gray-800 hover:text-violet-600 text-lg font-semibold">
                                Awards
                            </Link>
                        </li>
                        <li>
                            <Link to="/contact" className="block py-2 text-gray-800 hover:text-violet-600 text-lg font-semibold">
                                Contact Us
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;
