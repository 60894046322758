import React, { useState } from 'react';
import logo from '../../img/CN-Charity-and-PR-blog-removebg-preview.png';
import presidentImage from '../../img/Ronak-shah.jpg';

import { FaFacebook, FaWhatsapp, FaInstagram, FaTwitter, FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FaCircleCheck } from "react-icons/fa6";

function President() {
    const [currentLanguage, setCurrentLanguage] = useState('english');
    const [showFullText, setShowFullText] = useState(false);

    const translations = {
        english: {
            aboutHeader: 'About Our Foundation',
            aboutText1:
                'Samvedana Foundation, Sangli is an organization in western Maharashtra working for orphans, poor and destitute people as well as animal protection in Sangli district. Sanvedana Foundation was founded on 4/5/2018. Sanvedana Foundation aims to promote social welfare through Sanjeda Gaushala, Mansavaran Rehabilitation Center, Jamari, Food and Life Services as well as Dramafa to help hungry animals.',
            donateButton: 'Get Donate',
            associatedText:
                "Sudarshan Aluminium India Ltd. was established in Miraj, in the field of aluminium profile and extrusion production, 12 years ago. We have a 20-year-long experience in the production of aluminium profiles. I, Ronak Shah, have been involved in social work for the past 10 years, both directly and indirectly. On April 16, 2018, with the help of my wife and mother, we founded Samvedna Foundation in Sangli. Today, The Samvedna Foundation organizes various programs such as Samvedna Gaushala in Sangli, Manasvardhan Punarvasan Kendra in Miraj, Annapurna Tiffin Service, and Samvedna Skill Development Program.",
            // missionhead: "Mission of the Foundation",
            // missiontext: "Our mission is to provide compassionate and comprehensive assistance to the most vulnerable members of our society, including the weak, poor, homeless, and elderly. We are also dedicated to the care and protection of animals and birds. Through our programs and initiatives, we aim to foster a community where every individual and living being can live with dignity, respect, and the support they need to thrive.",
            // visionhead: "Objectives of the Foundation:",
            // visiontext1: "Our Objective is to provide extensive assistance and support to the weak, poor, homeless, and elderly in society, empowering them to overcome social challenges and lead dignified lives. We are committed to addressing social problems, treating the needy with compassion, and offering comprehensive veterinary care to injured animals. Additionally, we provide free ambulance services to ensure that patients receive timely and adequate medical attention.",
            // visiontext2: "We envision a society where every person, regardless of their circumstances, has the opportunity to lead a life of dignity and purpose. A community where animals and birds are treated with kindness and respect, and where people come together to support and uplift one another. Through our dedicated efforts, we strive to create lasting change and a brighter future for all.",

            projecthead: "Projects of the Foundation: ",
            projecttext: "The Samvedna Foundation in Sangli is an organization that works for orphans, the poor, and the needy, as well as for animal protection in western Maharashtra. The Samvedna Foundation was established on May 4, 2018. The Samvedna Foundation operates Samvedna Gaushala, Manasvardhan Punarvasan Kendra in Miraj, Annapurna Tiffin Service, and provides assistance to injured animals and social bonding activities under this center.",
            gaushalahead: "Samvedna Gaushala:",
            gaushalatext: "To provide a safe and nurturing environment for cows, ensuring they receive proper nutrition, medical care, and rehabilitation.",
            manasvardhanhead: "Manasvardhan Punarvasan Kendra (Miraj):            ",
            manasvardhantext: " To support individuals struggling with substance abuse and mental health issues through rehabilitation and recovery services.",
            tifinehead: "Annapurna Tiffin Service:            ",
            tifinetext: "To ensure that the needy and underprivileged receive nutritious and hygienic meals.",
            abhiyanhead: "Nasha Mukt Abhiyan:            ",
            abhiyantext: "To create awareness and reduce substance abuse within the community.",
            bloodhead: "Blood Donation Camps:            ",
            bloodtext: "To address the critical need for blood donations and ensure a steady supply for medical emergencies.",
            treehead: "Tree Plantation:            ",
            treetext: "To promote environmental conservation and enhance green cover in the community."
        },
        marathi: {
            aboutHeader: 'आमच्या संस्थेबद्दल',
            aboutText1:
                " संवेदना फाउंडेशन, सांगली ही पश्चिम महाराष्ट्रातील एक संस्था आहे जी सांगली जिल्ह्यातील अनाथ, गरीब आणि निराधार लोकांसाठी तसेच पशु संरक्षणासाठी काम करते. संवेदना फाउंडेशनची स्थापना 4/5/2018 रोजी झाली. संजेदा गौशाळा, मानसवरण पुनर्वसन केंद्र, जामरी, अन्न आणि जीवन सेवा तसेच भुकेलेल्या प्राण्यांना मदत करण्यासाठी ड्रामाफाच्या माध्यमातून सामाजिक कल्याणाचा प्रचार करणे हे सेन्सेदा फाउंडेशनचे उद्दिष्ट आहे. ",
            donateButton: 'दान करा',
            associatedText: "सुदर्शन ॲल्युमिनियम इंडिया लि.ची स्थापना 12 वर्षांपूर्वी मिरजेमध्ये ॲल्युमिनियम प्रोफाइल आणि एक्सट्रूजन उत्पादन क्षेत्रात करण्यात आली होती. आमच्याकडे ॲल्युमिनियम प्रोफाइलच्या निर्मितीचा 20 वर्षांचा अनुभव आहे.मी, रोनक शहा, गेल्या १० वर्षांपासून प्रत्यक्ष आणि अप्रत्यक्षपणे सामाजिक कार्यात सहभागी आहे. 16 एप्रिल 2018 रोजी, माझी पत्नी आणि आईच्या मदतीने आम्ही सांगली येथे सामवेदना फाउंडेशनची स्थापना केली. आज सामवेदना फाउंडेशन सांगलीतील संवेदना गोशाळा, मिरजेतील मनसवर्धन पुनर्वासन केंद्र, अन्नपूर्णा टिफिन सेवा आणि सामवेदना कौशल्य विकास कार्यक्रम असे विविध कार्यक्रम आयोजित करते.",
            // missionhead: "संस्थेची ध्येय:-",
            // missiontext: "समाजातील दीनदुबळ्या, गरीब, गरजू , निराधार , वृद्ध व्यक्तींना तसेच पशु पक्षी याना  सवातोपरी व्यापक स्वरूपात मदतीचा हात देणे . ",
            // visionhead: "संस्थेचे उद्दिष्ट:-",
            // visiontext: "समाजातील दुर्बल, गरीब, बेघर आणि वृद्धांना व्यापक मदत देणे, तसेच त्यांच्या सक्षमीकरणासाठी सहकार्य करणे. जखमी प्राण्यांना मदत करणे, पशुवैद्यकीय सेवा आणि त्यांची वाहतूक, तसेच रुग्णांना वैद्यकीय सुविधांपर्यंत नेण्यात सहकार्य आणि मदत करणे. समाजातील विविध सामाजिक समस्यांना मदत करणे.",
            projecthead: "फाउंडेशनचे प्रकल्प:",
            projecttext: "सांगलीतील संवेदना फाउंडेशन ही संस्था पश्चिम महाराष्ट्रातील अनाथ, गरीब आणि गरजूंसाठी तसेच प्राणी संरक्षणासाठी काम करते. सामवेदना फाउंडेशनची स्थापना मे 4, 2018 रोजी करण्यात आली. सामवेदना फाउंडेशन मिरजेतील सामवेदना गौशाळा, मनसवर्धन पुनर्वासन केंद्र, अन्नपूर्णा टिफिन सेवा चालवते आणि या केंद्रांतर्गत जखमी जनावरांना मदत आणि सामाजिक बांधिलकी उपक्रम प्रदान करते.",
            gaushalahead: "समवेदना गौशाला:            ",
            gaushalatext: "गाईंसाठी सुरक्षित आणि पोषणक्षम वातावरण प्रदान करणे, त्यांना योग्य पोषण, वैद्यकीय काळजी आणि पुनर्वसन सुनिश्चित करणे.",
            manasvardhanhead: "मनस्वर्धन पुनर्वसन केंद्र (मिरज):            ",
            manasvardhantext: "व्यसनमुक्ती आणि मानसिक आरोग्य समस्यांशी संघर्ष करणाऱ्या व्यक्तींना पुनर्वसन आणि पुनर्प्राप्ती सेवांद्वारे समर्थन देणे.",
            tifinehead: "अन्नपूर्णा टिफिन सेवा:            ",
            tifinetext: "गरजू आणि दुर्बल लोकांना पौष्टिक आणि स्वच्छ अन्न मिळणे सुनिश्चित करणे.",
            abhiyanhead: "नशा मुक्त अभियान:            ",
            abhiyantext: "समुदायामध्ये नशेची जागरूकता निर्माण करणे आणि नशेच्या वापरात कपात करणे.",
            bloodhead: "रक्तदान शिबिरे:            ",
            bloodtext: "रक्तदानाच्या अत्यावश्यक गरजांना सामोरे जाणे आणि वैद्यकीय आणीबाणीसाठी स्थिर पुरवठा सुनिश्चित करणे.",
            treehead: "वृक्षारोपण:            ",
            treetext: "पर्यावरण संरक्षणाला प्रोत्साहन देणे आणि समुदायातील हरित आवरण वाढवणे.",


        },
    };

    const handleLanguageChange = (language) => {
        setCurrentLanguage(language);
    };
    const toggleFullText = () => {
        setShowFullText(!showFullText);
    };

    return (
        <div className="p-0 lg:p-16">
            <div class="p-8 bg-white  mt-24">
                <div class="grid grid-cols-1 md:grid-cols-3">
                    <div class="grid grid-cols-3 text-center order-last md:order-first mt-20 md:mt-0">

                    </div>
                    <div className="relative">
                        <div className="w-48 h-48 bg-indigo-100 mx-auto rounded-full shadow-2xl absolute inset-x-0 top-0 -mt-24 flex items-left justify-center text-indigo-500">
                            <img src={presidentImage} alt='President' className="w-48 h-48 mb-4 rounded-full " />
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center mt-36 sm:flex-row sm:mt-0">
                        <div className="flex items-center space-x-5">
                            <a href="https://www.facebook.com/ronak.sudarshan" target="_blank" rel="noopener noreferrer" className="social-btn-facebook text-blue-500 hover:scale-110 transition duration-300">
                                <FaFacebook className="w-8 h-8" />
                            </a>
                            <a href="https://wa.me/919423001415" target="_blank" rel="noopener noreferrer" className="social-btn-facebook text-green-500 hover:scale-110 transition duration-300">
                                <FaWhatsapp className="w-8 h-8" />
                            </a>
                            <a href="https://www.instagram.com/ronak_shah1415/" target="_blank" rel="noopener noreferrer" className="social-btn-facebook text-pink-600 hover:scale-110 transition duration-300">
                                <FaInstagram className="w-8 h-8" />
                            </a>
                            <a href="https://twitter.com/Ronaksh46083798" target="_blank" rel="noopener noreferrer" className="social-btn-facebook text-blue-400 hover:scale-110 transition duration-300">
                                <FaTwitter className="w-8 h-8" />
                            </a>
                            <a href="mailto:sail1415@gmail.com" className="social-btn-facebook text-orange-500 hover:scale-110 transition duration-300">
                                <FaEnvelope className="w-8 h-8" />
                            </a>
                        </div>
                    </div>

                </div>
                <div class="sm:mt-20 text-center border-b pb-12 ">
                    <h1 class="text-4xl font-medium text-gray-700">Shri.Ronak Rajnikant Shah</h1>
                    <div class="text-center">
                        <ul class="mt-4 ml-96">
                            <li class="text-gray-500 d-flex align-items-center mt-3"><span class="me-2"><FaCircleCheck /></span><span class="font-bold">President :- </span> Samvedana Foundation Sangli</li>
                            <li class="text-gray-500 d-flex align-items-center"><span class="me-2"><FaCircleCheck /></span><span class="font-bold">Managing Director :- </span> Sudarshan Alluminium India Ltd Miraj</li>
                            <li class="text-gray-500 d-flex align-items-center"><span class="me-2"><FaCircleCheck /></span><span class="font-bold">President :- </span>BJP Vyapari Aaghadi Sanghatana Sangli</li>
                            <li class="text-gray-500 d-flex align-items-center"><span class="me-2"><FaCircleCheck /></span><span class="font-bold">Committee member :- </span> Smt. Champaben Balchand Shah Mahila Mahavidyalaya Sangli</li>
                            <li class="text-gray-500 d-flex align-items-center"><span class="me-2"><FaCircleCheck /></span><span class="font-bold">Trustee :- </span> Jawahar Society Sri Jain Shwetamber Murti Pujak Sangh Sangli</li>
                            <li class="text-gray-500 d-flex align-items-center"><span class="me-2"><FaCircleCheck /></span><span class="font-bold">Trustee :- </span> Shree Adinath Derasar Sangli</li>
                        </ul>
                    </div>


                </div>



                <div class="mt-12 flex flex-col justify-center">
                    <p class="text-gray-600 text-center  lg:px-16">
                        {showFullText ? translations[currentLanguage].associatedText : translations[currentLanguage].associatedText.slice(0, 100) + '...'}
                    </p>
                    <button class="text-indigo-500 py-2 px-4  font-medium mt-4" onClick={toggleFullText}>
                        {showFullText ? 'Show less' : 'Show more about President'}
                    </button>
                </div>
                <div className="flex  justify-center items-center p-8">
                    <div className="flex flex-col items-center w-full mb-8">
                        <img src={logo} alt='Foundation Logo' className="w-96 h-96 mb-4" />
                    </div>
                    <div className="text-center w-full">
                        <div className="flex justify-center  mb-4">
                            <button className="border border-gray-400 rounded py-2 px-4 mr-2" onClick={() => handleLanguageChange('english')}>English</button>
                            <button className="border border-gray-400 rounded py-2 px-4" onClick={() => handleLanguageChange('marathi')}>Marathi</button>
                        </div>
                        <h1 className="text-3xl font-bold mb-4">{translations[currentLanguage].aboutHeader}</h1>
                        <p className="text-gray-700 mb-4 text-left">{translations[currentLanguage].aboutText1}</p>

                        <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded">
                            <Link to="/contact"> {translations[currentLanguage].donateButton}</Link>
                        </button>

                    </div>
                </div>
                {/* <div className='flex flex-wrap justify-center items-stretch'>
                    <div className='w-full md:w-1/2 px-4 mb-8'>
                        <div className='bg-blue-200 p-6 rounded-lg shadow-lg flex flex-col h-full'>
                            <h1 className='text-2xl font-semibold text-blue-800 mb-4'>{translations[currentLanguage].missionhead}</h1>
                            <p className='text-gray-700 flex-grow'>{translations[currentLanguage].missiontext}</p>
                        </div>
                    </div>
                    <div className='w-full md:w-1/2 px-4 mb-8'>
                        <div className='bg-green-200 p-6 rounded-lg shadow-lg flex flex-col h-full'>
                            <h1 className='text-2xl font-semibold text-green-800 mb-4'>{translations[currentLanguage].visionhead}</h1>
                            <p className='text-gray-700 flex-grow'>{translations[currentLanguage].visiontext1}</p>
                            <p className='text-gray-700 flex-grow'>{translations[currentLanguage].visiontext2}</p>

                        </div>
                    </div>
                </div> */}
                <div className='bg-green-100 p-6 rounded-lg shadow-lg flex flex-col h-full'>
                    <h1 className='text-2xl font-semibold text-green-800 mb-4'>{translations[currentLanguage].projecthead}</h1>
                    <p className='text-gray-700 flex-grow'>{translations[currentLanguage].projecttext}</p>
                    <div className='mt-4 grid lg:grid-cols-2 sm:grid-cols-1 gap-4'>
                        <div>
                            <h1 className='text-xl font-semibold'>{translations[currentLanguage].gaushalahead}</h1>
                            <p className='text-gray-700 flex-grow mb-4'>{translations[currentLanguage].gaushalatext}</p>
                        </div>
                        <div>
                            <h1 className='text-xl font-semibold'>{translations[currentLanguage].manasvardhanhead}</h1>
                            <p className='text-gray-700 flex-grow mb-4'>{translations[currentLanguage].manasvardhantext}</p>
                        </div>
                        <div>
                            <h1 className='text-xl font-semibold'>{translations[currentLanguage].tifinehead}</h1>
                            <p className='text-gray-700 flex-grow mb-4'>{translations[currentLanguage].tifinetext}</p>
                        </div>
                        <div>
                            <h1 className='text-xl font-semibold'>{translations[currentLanguage].abhiyanhead}</h1>
                            <p className='text-gray-700 flex-grow mb-4'>{translations[currentLanguage].abhiyantext}</p>
                        </div>
                        <div>
                            <h1 className='text-xl font-semibold'>{translations[currentLanguage].bloodhead}</h1>
                            <p className='text-gray-700 flex-grow mb-4'>{translations[currentLanguage].bloodtext}</p>
                        </div>
                        <div>
                            <h1 className='text-xl font-semibold'>{translations[currentLanguage].treehead}</h1>
                            <p className='text-gray-700 flex-grow mb-4'>{translations[currentLanguage].treetext}</p>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    );
}

export default President;
