import React from 'react'
import Contactcomponent from '../../../Contact/Contactcomponent'
import Navbar from '../../../Navbar/Navbar'
import Socialletest from './Socialletest'
import GallarySocial from './GallarySocial'

function Social() {
  return (
    <div>
      <div><Navbar /></div>
      <div className="text-3xl lg:text-4xl text-green-700 font-bold mb-4 text-center lg:text-left mt-20 sm:pt-0">Our Latest News</div>
      <div>< Socialletest /></div>
      <div><GallarySocial /></div>

      <div><Contactcomponent /></div>


    </div>
  )
}

export default Social
