import React, { useState, useEffect } from 'react';

function ActivitiesSamvedana() {
  const [activity, setActivity] = useState(null);

  useEffect(() => {
    fetchActivity();
  }, []);

  const fetchActivity = async () => {
    try {
      const response = await fetch('https://samvedanafoundation.com/exe_files/latest_activity_samvedana.php');
      if (!response.ok) {
        throw new Error('Failed to fetch activity');
      } 
      const data = await response.json();

      if (data.length > 0) {
        setActivity(data[0]); // Set the first object in the array as the activity
      } else {
        throw new Error('No activity data found');
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div>
      {activity ? (
        <div className='sectiondiv'>
          <h1>{activity.Head}</h1>
          <div className="contentdiv">
            <h4>{activity.ShortDescription}</h4>
            <p>{activity.Description}</p>
          </div>
          <div className="img">
            <img 
              src={`https://samvedanafoundation.com/exe_files/images/activity_images/${activity.PhotoPath}`} 
              alt={activity.ShortDescription || 'Activity'} 
            />
            <span>{activity.Date} {activity.Place}</span>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default ActivitiesSamvedana;
