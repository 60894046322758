import React from 'react'
import { Link } from 'react-router-dom'
import product1 from '../../../img/Ghee.jpg';
import product2 from '../../../img/cow-dung-dhoop-removebg-preview (1).png';
import product3 from '../../../img/Panchgavy Sambrani.png';
import product4 from '../../../img/CowDungDiya-removebg-preview.png';
function GaushalaProduct() {
    return (
        <div className="py-2 col-span-12 xl:col-span-3 lg:col-span-4">
            <div className="mt-8 space-x-5 flex justify-center">
                <h1 className="pb-5 text-3xl font-bold  text-yellow-600">Our Products from Samvedana Gaushala Sangli</h1>
            </div>

            <div className="flex  justify-evenly divide-y dark:divide-gray-700">
                <div className="flex px-1 py-4">
                    <img alt="" className="flex-shrink-0 object-cover w-20 h-20 mr-4 dark:bg-gray-500" src={product1} />
                    <div className="flex flex-col flex-grow">
                        <h2 className="font-serif text-xl font-bold mb-4">Gir A2 Ghee</h2>
                        <Link to='/product'>   <button className="bg-gray-800 text-xs text-white px-2 py-1 font-semibold rounded uppercase hover:bg-gray-700">Add to Cart</button></Link>
                    </div>
                </div>
                <div className="flex px-1 py-4">
                    <img alt="" className="flex-shrink-0 object-cover w-20 h-20 mr-4 dark:bg-gray-500" src={product2} />
                    <div className="flex flex-col flex-grow">
                        <h2 className="font-serif text-xl font-bold mb-4">Cow Dung Diya</h2>
                        <Link to='/product'>   <button className="bg-gray-800 text-xs text-white px-2 py-1 font-semibold rounded uppercase hover:bg-gray-700">Add to Cart</button></Link>
                    </div>
                </div>
                <div className="flex px-1 py-4">
                    <img alt="" className="flex-shrink-0 object-cover w-20 h-20 mr-4 dark:bg-gray-500" src={product3} />
                    <div className="flex flex-col flex-grow">
                        <h2 className="font-serif text-xl font-bold mb-4">Panchgavy Sambrani</h2>
                        <Link to='/product'>    <button className="bg-gray-800 text-xs text-white px-2 py-1 font-semibold rounded uppercase hover:bg-gray-700">Add to Cart</button></Link>
                    </div>
                </div>
                <div className="flex px-1 py-4">
                    <img alt="" className="flex-shrink-0 object-cover w-20 h-20 mr-4 dark:bg-gray-500" src={product4} />
                    <div className="flex flex-col flex-grow">
                        <h2 className="font-serif text-xl font-bold mb-4">Cow Dung Dhoop</h2>
                        <Link to='/product'>     <button className="bg-gray-800 text-xs text-white px-2 py-1 font-semibold rounded uppercase hover:bg-gray-700">Add to Cart</button></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GaushalaProduct
