import React, { useState, useEffect } from 'react';
import { MdLocalPhone, MdOutlineMail } from "react-icons/md";
import { RiWhatsappFill } from "react-icons/ri";
import { FaFacebookF, FaTwitter, FaYoutube } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { RiAdminFill } from "react-icons/ri";

function UpperNav() {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const toggleNav = () => {
        if (window.innerWidth < 768) { // Adjust the breakpoint according to your design
            setIsNavOpen(!isNavOpen);
        }
    };


    useEffect(() => {
        const handleScroll = () => {
            if (window.innerWidth >= 768) { // Adjust the breakpoint according to your design
                if (window.scrollY > 0) {
                    setIsScrolled(true);
                } else {
                    setIsScrolled(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
            {isScrolled && (
                <div className='flex flex-col md:flex-row justify-evenly items-center p-1 md:px-8 bg-gray-700'>
                    <div className='flex items-center space-x-2 md:space-x-4'>
                        <MdLocalPhone className='text-white text-xl font-bold' />
                        <p className='text-white text-lg font-bold'>+91 9423001415</p>
                    </div>
                    <div className='border-b md:border-b-0 border-white md:border-l md:h-6'></div>
                    <div className='flex items-center space-x-2 md:space-x-4'>
                        <MdOutlineMail className='text-white text-xl font-bold' />
                        <p className='text-white text-lg font-bold'>samvedanafoundation.sangli@gmail.com</p>
                    </div>
                    <div className='border-b md:border-b-0 border-white md:border-l md:h-6'></div>
                    <div className='flex items-center space-x-2 md:space-x-8 hidden md:flex'>
                        <a href="https://www.facebook.com/samvedanafoundation" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-green-500">
                            <FaFacebookF className='text-white text-lg' />
                        </a>
                        <a href="https://wa.me/919423001415" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-green-500">
                            <RiWhatsappFill className='text-white text-lg' />
                        </a>
                        <a href="https://www.instagram.com/samvedana_foundation_sangli/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-green-500">
                            <AiFillInstagram className='text-white text-lg' />
                        </a>
                        <a href="https://twitter.com/Ronaksh46083798" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-green-500">
                            <FaTwitter className='text-white text-lg' />
                        </a>
                        <a href="https://www.youtube.com/@samvedanafoundation4620" className="text-gray-400 hover:text-green-500">
                            <FaYoutube className='text-white text-lg' />
                        </a>
                    </div>
                    <div className='border-b md:border-b-0 border-white md:border-l md:h-6'></div>
                    <div className='flex items-center md:hidden'>
                        <button onClick={toggleNav} className='text-white focus:outline-none'>
                            <svg className='w-8 h-8' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                {isNavOpen ? (
                                    <path d='M19 13H5v-2h14v2z' fill='currentColor' />
                                ) : (
                                    <>
                                        <path d='M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z' fill='currentColor' />
                                    </>
                                )}
                            </svg>
                        </button>
                    </div>
                    <div>
                        <Link to='/contact'>
                            <button className='hidden md:block bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded md:ml-4'>
                                Donate Now
                            </button>
                        </Link>
                    </div>
                    {/* <div className='border-b md:border-b-0 border-white md:border-l md:h-6'></div> */}
                    <Link to='/login'>
                        <div className='text-white font-bold'><RiAdminFill /></div>
                    </Link>
                </div>
            )}

            {isNavOpen && (
                <div className='flex justify-evenly bg-gray-700'>
                    <div className='md:hidden  flex'>
                        <div className='flex text-center gap-4 text-2xl items-center '>
                            <a href="https://www.facebook.com/samvedanafoundation" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-green-500">
                                <FaFacebookF className='text-white' />
                            </a>
                            <a href="https://wa.me/919423001415" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-green-500">
                                <RiWhatsappFill className='text-white' />
                            </a>
                            <a href="https://www.instagram.com/samvedana_foundation_sangli/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-green-500">
                                <AiFillInstagram className='text-white' />
                            </a>
                            <a href="https://twitter.com/Ronaksh46083798" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-green-500">
                                <FaTwitter className='text-white' />
                            </a>
                            <a href="https://www.youtube.com/@samvedanafoundation4620" className="text-gray-400 hover:text-green-500">
                                <FaYoutube className='text-white' />
                            </a>
                        </div>
                    </div>
                    <div>
                        <Link to='/contact'>
                            <button className='md:hidden bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded md:ml-4 m-2 '>
                                Donate Now
                            </button>
                        </Link>
                    </div>
                </div>
            )}
            <hr
                className="w-full mx-auto"
                style={{
                    background: 'linear-gradient(to right, #8A2BE2, #00ff00, #8A2BE2)',
                    height: '4px',
                    border: 'none'
                }}
            />

        </>
    );
}

export default UpperNav;
