import React from 'react'
import ActivitiesSamvedana from './ActivitiesSamvedana'
import CarasoualData from './CarasoualData'
import Contactcomponent from '../../Contact/Contactcomponent'

function HomeSamvedhana() {
  return (
    <div>
<div className='text-center text-4xl mt-5 text-green-700 font-bold mb-8'>Our Latest Social Activism Effort</div>
    <div className='mt-8 sm:pt-0'>
      <ActivitiesSamvedana/></div>
      <div><CarasoualData/></div>

    </div>
  )
}

export default HomeSamvedhana
