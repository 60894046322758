import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ListHomePage() {
    const [homeList, setHomeList] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchHomeList();
    }, []);

    const fetchHomeList = async () => {
        try {
            const response = await axios.get('https://samvedanafoundation.com/exe_files/list_home_banner.php');
            setHomeList(response.data);
        } catch (error) {
            console.error("An error occurred while fetching the homepage list:", error.message);
            setError("An error occurred while fetching data.");
        }
    };

    const deleteProfessionalAward = async (index) => {
        try {
            const home = homeList[index];
            if (!home || !home.Id) {
                throw new Error("Id not found");
            }
    
            const response = await axios.post('https://samvedanafoundation.com/exe_files/delete_home_banner.php', {
                Id: home.Id
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            if (response.data.response === "D") {
                setHomeList(homeList.filter((_, i) => i !== index));
                alert("Home Banner deleted successfully");
            } else {
                alert("Error: " + response.data.msg || "Unknown error");
            }
        } catch (error) {
            console.error("Error deleting Professional Award:", error.message);
            alert("Error deleting Professional Award. Please try again.");
        }
    };

    return (
        <div>
            <div className="text-gray-900">
                <div className="p-4 flex">
                    <h1 className="text-3xl">List HomePage Images</h1>
                </div>
                {error && <p className="text-red-500">{error}</p>}
                <div className="px-3 py-4 flex justify-center">
                    <table className="w-full text-md bg-white shadow-md rounded mb-4">
                        <thead className="bg-gray-200">
                            <tr>
                                <th className="text-left p-3 px-5">Image</th>
                                <th className="text-left p-3 px-5">Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {homeList.map((homeItem, index) => (
                                <tr key={homeItem.Id} className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                                    <td className="p-3 px-5">
                                        <img 
                                            src={`https://samvedanafoundation.com/exe_files/images/banners/${homeItem.PhotoPath}`} 
                                            alt="banner" 
                                            className="max-w-[100px] max-h-[100px] object-cover" 
                                        />
                                    </td>
                                    <td className="p-3 px-5 text-right">
                                        <button
                                            type="button"
                                            onClick={() => deleteProfessionalAward(index)}
                                            className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default ListHomePage;
