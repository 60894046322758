import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ListVideo() {
  const [videoList, setVideoList] = useState([]);

  useEffect(() => {
    fetchVideoList();
  }, []);

  const fetchVideoList = async () => {
    try {
      const response = await axios.get('https://samvedanafoundation.com/exe_files/list_samvedana_video.php');
      setVideoList(response.data);
    } catch (error) {
      console.error("An error occurred while fetching the video list:", error.message);
    }
  };

  const handleEdit = (id) => {
    // Add your edit logic here
    console.log(`Editing video with id ${id}`);
  };

  const handleDelete = async (index) => {
    try {
        const VideoList = videoList[index];
        if (!VideoList || !VideoList.VideoId) {
            throw new Error("Item or ActivityId not found");
        }

        const response = await axios.post('https://samvedanafoundation.com/exe_files/delete_samvedana_video.php', 
            JSON.stringify({ Id: VideoList.VideoId }), 
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );

        if (response.data.response === "D") {
          setVideoList(videoList.filter((_, i) => i !== index));
            alert("Deleted successfully");
        } else {
            alert("Error: " + (response.data.msg || "Unknown error"));
        }
    } catch (error) {
        console.error("Error deleting:", error.message);
        alert("Error deleting. Please try again.");
    }
};

  return (
    <div>
      <div className="text-gray-900">
        <div className="p-4 flex">
          <h1 className="text-3xl">List Youtube Video</h1>
        </div>
        <div className="px-3 py-4 flex justify-center">
          <table className="w-full text-md bg-white shadow-md rounded mb-4">
            <thead>
              <tr>
                <th className="text-left p-3 px-5">Id</th>
                <th className="text-left p-3 px-5">Title</th>
                <th className="text-left p-3 px-5">URL Link</th>
                <th className="text-left p-3 px-5">Edit/Delete</th>
              </tr>
            </thead>
            <tbody>
              {videoList.map((video, index) => (
                <tr key={index} className={index % 2 === 0 ? 'border-b hover:bg-orange-100 bg-gray-100' : 'border-b hover:bg-orange-100'}>
                  <td className="p-3 px-5">{video.VideoId}</td>
                  <td className="p-3 px-5">{video.Name}</td>
                  <td className="p-3 px-5">{video.URL}</td>
                  <td className="p-3 px-5 flex justify-end">
                    <button type="button" onClick={() => handleEdit(video.id)} className="mr-3 text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline">Edit</button>
                    <button
                                            type="button"
                                            onClick={() => handleDelete(index)}
                                            className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                        >
                                            Delete
                                        </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ListVideo;
