import React, { useState, useEffect } from 'react';

// Table Row Component
const ProductRow = ({ product, onDelete }) => {
    const { ProductId, Name, ImageURL, Price, Weight, Description, PhotoPath } = product;

    return (
        <tr className={ProductId % 2 === 0 ? 'border-b hover:bg-orange-100 bg-gray-100' : 'border-b hover:bg-orange-100'}>
            <td className="p-3 px-5">{ProductId}</td>
            <td className="p-3 px-5">{Name}</td>
            <td className="p-3 px-5">{ImageURL}</td>
            <td className="p-3 px-5">{Price}</td>
            <td className="p-3 px-5">{Weight}</td>
            <td className="p-3 px-5">{Description}</td>
            <td className="p-3 px-5">
                <img src={`https://samvedanafoundation.com/exe_files/images/product_images/${PhotoPath}`} alt="Product" style={{ maxWidth: '100px' }} />
            </td>
            <td className="p-3 px-5 flex justify-end">
                <button type="button" className="mr-3 text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline">Edit</button>
                <button type="button" className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline" onClick={() => onDelete(ProductId)}>Delete</button>
            </td>
        </tr>
    );
};

// ListProduct Component
function ListProduct() {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = () => {
        fetch('https://samvedanafoundation.com/exe_files/list_product.php')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch products');
                }
                return response.json();
            })
            .then(data => {
                setProducts(data);
            })
            .catch(error => {
                console.error("An error occurred while fetching the product list:", error.message);
            });
    };

    const handleDelete = (ProductId) => {
        fetch(`https://samvedanafoundation.com/exe_files/delete_product.php?ProductId=${ProductId}`, {
            method: 'DELETE'
        })
        .then(response => {
            console.log(response); // Log response to check if fetch was successful
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json(); // Parse JSON response
        })
        .then(data => {
            if (data.response === "D") {
                // Update the product list after successful deletion
                fetchProducts();
                alert("Product deleted successfully.");
            } else {
                throw new Error(data.msg || 'Failed to delete product');
            }
        })
        .catch(error => {
            console.error("An error occurred while deleting the product:", error.message);
        });
    };
    
    
    

    return (
        <div>
            <div className="text-gray-900 ">
                <div className="p-4 flex">
                    <h1 className="text-3xl">List Products</h1>
                </div>
                <div className="px-3 py-4 flex justify-center">
                    <table className="w-full text-md bg-white shadow-md rounded mb-4">
                        <thead>
                            <tr>
                                <th className="text-left p-3 px-5">Id</th>
                                <th className="text-left p-3 px-5">Name</th>
                                <th className="text-left p-3 px-5">Product Link</th>
                                <th className="text-left p-3 px-5">Price</th>
                                <th className="text-left p-3 px-5">Weight</th>
                                <th className="text-left p-3 px-5">Quantity</th>
                                <th className="text-left p-3 px-5">Image</th>
                                <th className="text-left p-3 px-5">Edit/Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map(product => (
                                <ProductRow key={product.prodId} product={product} onDelete={handleDelete} />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default ListProduct;
