import React, { useState, useEffect } from 'react';
import axios from 'axios';

function HomeAbout() {
  const [slides, setSlides] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const autoplayInterval = 5000;
  let autoplayTimer;

  useEffect(() => {
    // Fetch images from the backend
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://samvedanafoundation.com/exe_files/list_home_banner.php');
        const updatedSlides = response.data.map(slide => ({
          ...slide,
          imageURL: `https://samvedanafoundation.com/exe_files/images/banners/${slide.PhotoPath}`
        }));
        setSlides(updatedSlides);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);

  useEffect(() => {
    if (slides.length > 0) {
      handleAutoplay();
    }
    return () => clearInterval(autoplayTimer);
  }, [slides, currentSlide]);

  const handleAutoplay = () => {
    autoplayTimer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, autoplayInterval);
  };

  const handleChangeSlide = (index) => {
    setCurrentSlide(index);
    clearInterval(autoplayTimer);
    handleAutoplay(3000); // Restart autoplay when manually changing slides
  };

  return (
    <div>
      <main>
        <div
          className="relative pt-16 pb-32 flex content-center items-center justify-center"
          style={{
            minHeight: '100vh',
            backgroundImage: slides.length ? `url(${slides[currentSlide].imageURL})` : 'none',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="container mx-auto">
            <div className="flex justify-center items-center h-screen">
              <div className="w-full lg:w-6/12 px-4 text-center">
                <div className="bg-black bg-opacity-50 p-8 mt-40 rounded-lg">
                  <h1 className="text-white text-5xl font-bold">Samvedana</h1>
                  <h1 className="text-white text-3xl font-bold">Foundation, Sangli</h1>
                  <p className="mt-2 text-lg text-white font-semibold">
                    Empowering Lives, Caring for Animals
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Slide indicators */}
          <div className="absolute bottom-0 left-0 right-0 flex justify-center">
            {slides.map((_, index) => (
              <button
                key={index}
                onClick={() => handleChangeSlide(index)}
                className={`w-4 h-4 mx-2 rounded-full focus:outline-none ${currentSlide === index ? 'bg-white' : 'bg-gray-500'}`}
              ></button>
            ))}
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: '70px' }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="text-gray-300 fill-current" points="2560 0 2560 100 0 100"></polygon>
            </svg>
          </div>
        </div>

        {/* Rest of your component... */}
      </main>
    </div>
  );
}

export default HomeAbout;
