import React, { useState } from 'react';
import img1 from "../../../../img/10-removebg-preview.png";
import img2 from "../../../../img/6-removebg-preview.png";
import img3 from "../../../../img/1-removebg-preview.png";
import img4 from "../../../../img/9-removebg-preview.png";
import { Link } from 'react-router-dom';

const ProductCard = ({ imgSrc, altText, title, details, price, onBuyClick, link }) => (
    <div className="flex flex-col object-contain border-4 border-green-100 items-center bg-white rounded-2xl overflow-hidden shadow-xl hover:shadow-2xl transition duration-300 w-72 mx-2 my-4">
        <img className="rounded-t-xl w-full h-64 object-cover mt-4" src={imgSrc} alt={altText} />
        <div className="p-4 flex flex-col items-start flex-grow">
            <h1 className="text-lg font-bold text-gray-800">{title}</h1>
            {details.map((detail, index) => (
                <p className="mt-2 text-gray-600 font-medium" key={index}>
                    {detail.label}: <span className="font-semibold text-black">{detail.value}</span>
                </p>
            ))}
            <p className="mt-2 text-gray-600 font-medium">Price: Rs.<span className="font-semibold text-black"> {price.toFixed(2)}</span></p>
        </div>
        <div className="p-4 w-full mt-auto">
            <Link to={link}>
                <button
                    className="w-full text-white text-md font-semibold bg-green-400 hover:bg-green-800 py-2 px-4 rounded-lg shadow-md hover:shadow-lg transition duration-500 transform-gpu hover:scale-105"
                    onClick={onBuyClick}
                >
                    Buy Now
                </button>
            </Link>
        </div>
    </div>
);


function ProductHome() {
    const [weight, setWeight] = useState('0.5kg');
    const [price, setPrice] = useState(950);

    const handleWeightChange = (selectedWeight) => {
        if (selectedWeight === '1kg') {
            setWeight('1kg');
            setPrice(1800);
        } else {
            setWeight('0.5kg');
            setPrice(950);
        }
    };

    return (
        <div className='mt-8 md:mt-12 lg:mt-16'>
            <h1 className="text-center text-4xl text-green-700 font-bold mb-8">Our Samvedana Gaushala Product</h1>
            <div className="flex flex-wrap justify-center space-x-4 space-y-4">
                <div className="flex flex-col object-contain border-4 border-green-100 items-center bg-white rounded-2xl overflow-hidden shadow-xl hover:shadow-2xl transition duration-300 w-72 mx-2 my-4">
                    <img className="mt-4 rounded-t-xl w-full h-64 object-cover" src={img1} alt="GIR A2 GHEE" />
                    <div className="p-4 flex flex-col items-start flex-grow">
                        <h1 className="text-lg font-bold">GIR A2 GHEE</h1>
                        <div className="mt-2 flex space-x-2">
                            <button
                                className={`px-4 py-2 rounded-lg ${weight === '0.5kg' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                                onClick={() => handleWeightChange('0.5kg')}
                            >
                                0.5kg
                            </button>
                            <button
                                className={`px-4 py-2 rounded-lg ${weight === '1kg' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                                onClick={() => handleWeightChange('1kg')}
                            >
                                1kg
                            </button>
                        </div>
                        <p className="mt-2 text-gray-600 font-medium">Weight: <span className="font-semibold text-black">{weight}</span></p>
                        <p className="mt-2 text-gray-600 font-medium">Price: Rs.<span className="font-semibold text-black">{price.toFixed(2)}</span></p>
                    </div>
                    <div className="p-4 w-full mt-auto">
                       <Link to="/addGhee"> <button className="w-full text-white text-md font-semibold bg-green-400 hover:bg-green-800 py-2 px-4 rounded-lg shadow-md hover:shadow-lg transition duration-500 transform-gpu hover:scale-105">
                            Buy Now
                        </button></Link>
                    </div>
                </div>
                <ProductCard
    imgSrc={img2}
    altText="Cow Dung Diya"
    title="COW DUNG DIYA"
    details={[{ label: "Pieces", value: "10 PCS" }]}
    price={100.00}
    onBuyClick={() => console.log('Buy Now Clicked for Cow Dung Diya')}
    link='/adddiya'
/>

<ProductCard
    imgSrc={img3}
    altText="Cow Dung Dhoop"
    title="COW DUNG DHOOP"
    details={[{ label: "Pieces", value: "14 PCS" }]}
    price={80.00}
    onBuyClick={() => console.log('Buy Now Clicked for Cow Dung Dhoop')}
    link='/adddhoop' 
/>

<ProductCard
    imgSrc={img4}
    altText="Panchgavy Sambrani"
    title="PANCHGAVY SAMBRANI"
    details={[{ label: "Cups", value: "12 CUP" }]}
    price={110.00}
    onBuyClick={() => console.log('Buy Now Clicked for Panchgavy Sambrani')}
    link='/addsamrani'
/>

            </div>
        </div>
    );
}

export default ProductHome;
